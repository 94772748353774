import React from 'react';

const Loader = () => {
  return (
    <div>
      <span className='loader'></span>
      <h4 className='text-[#8144E5] font-bold font-mono'>Loading...</h4>
    </div>
  );
};

export default Loader;
