import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateAdvertMedia = () => {
  const [media_file, setMedia_file] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFileChange = (e) => {
    setMedia_file(e.target.files[0]);
  };

  // getting media by id
  const getMediaById = useCallback(async () => {
    try {
      const response = await axios.get(`/adverts/ads/${id}`);
      setMedia_file(response.data.filename);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  }, [id]);

  useEffect(() => {
    getMediaById();
  }, [getMediaById]);

  // updating media file
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`/adverts/ads/${id}/media`, { media_file }, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      navigate(`/app/viewadvert/${id}`);
      toast.success('Media updated');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.')
      } else {
        toast.error('Error updating advert media.');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-full xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Update Advert Media.</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-4'>
            <label htmlFor='media'>Upload Media here :
              <input
                type='file'
                required
                name='media'
                placeholder='media type'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Updating...' : 'Update Advert Media'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAdvertMedia;
