import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'

const ViewUser = () => {

  const [userdetails, setUserdetails] = useState([]);
  const { id } = useParams();

  // getting user by id
  const getUserById = useCallback(async () => {
    try {
      const response = await axios.get(`/users/${id}`);
      if (response.data.kyc_profile !== null) {
        setUserdetails(response.data.kyc_profile);
      } else {
        setUserdetails('')
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  // verify advertisor kyc profile
  const verifyProfile = async (user_id) => {
    const confirmed = window.confirm('Are you sure you want to verify this advertisor profile?');
    if (confirmed) {
      try {
        await axios.put(`/users/${user_id}/verify-advertiser-kyc-profile`);
        toast.success('Advertisor Profile Verified');
        getUserById();
      } catch (error) {
        toast.error('Failed to verify advertisor profile');
        console.log(error);
      }
    }
  };

  // reject advertisor kyc profile
  const rejectProfile = async (user_id) => {
    const confirmed = window.confirm('Are you sure you want to reject this advertisor profile?');
    if (confirmed) {
      try {
        await axios.put(`/users/${user_id}/reject-advertiser-kyc-profile`);
        toast.success('Advertisor Profile Rejected');
        getUserById();
      } catch (error) {
        toast.error('Failed to reject advertisor profile');
        console.log(error);
      }
    }
  };

  return (
    <div className='mx-auto p-4'>
      <div className='p-4 bg-white rounded-lg'>
        <h3 className='text-center text-[#8144E5] font-bold mb-4'><u>User KYC Details</u></h3>
        <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
          <div>
            <p><span className='font-bold'>User Id : </span>{userdetails.user_id}</p>
          </div>
          <div>
            <p><span className='font-bold'>Document Type : </span>{userdetails.id_document_type}</p>
          </div>
          <div>
            <p><span className='font-bold'>ID Number : </span>{userdetails.id_number}</p>
          </div>
          <div>
            <p><span className='font-bold'>Document Identification No. : </span>{userdetails.identification_document_number}</p>
          </div>
          <div>
            <p><span className='font-bold'>Date of Birth : </span>{userdetails.date_of_birth}</p>
          </div>
          <div>
            <p><span className='font-bold'>Nationality : </span>{userdetails.nationality}</p>
          </div>
          <div>
            <p><span className='font-bold'>Residential Address : </span>{userdetails.residential_address}</p>
          </div>
          <div>
            <p><span className='font-bold'>Verification Status : </span>
              {!userdetails.verification_status || userdetails.verification_status === 'PENDING' ? (
                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
              ) : (
                <span className='bg-[#D9F8EB] text-[#2A8B4F] font-bold px-3 py-1 rounded-full'>Verified</span>
              )}
            </p>
          </div>
          <div>
            <p><span className='font-bold'>Created At : </span>
              {userdetails.time_created ? new Date(userdetails.time_created).toISOString().replace('T', ' ').slice(0, 19) : '....'}
            </p>
          </div>
          <div>
            <p><span className='font-bold'>Verified At : </span>
              {userdetails.time_verified ? new Date(userdetails.time_verified).toISOString().replace('T', ' ').slice(0, 19) : '....'}
            </p>
          </div>
          <div>
            <p><span className='font-bold'>Rejected At : </span>
              {userdetails.time_rejected ? new Date(userdetails.time_rejected).toISOString().replace('T', ' ').slice(0, 19) : '....'}
            </p>
          </div>
        </div>
        {userdetails !== null && userdetails !== '' && userdetails.id_number !== null && (
          userdetails.verification_status === 'VERIFIED' ? (
            <div className="mt-4 text-center">
              <button onClick={() => rejectProfile(userdetails.user_id)} className='bg-red-700 text-white px-5 py-1 rounded-md'>Reject Profile</button>
            </div> 
          ): (
            <div className="mt-4 text-center">
              <button onClick={() => verifyProfile(userdetails.user_id)} className='bg-blue-700 text-white px-5 py-1 rounded-md'>Verify Profile</button>
            </div> 
          )
        )}
        
      </div>
    </div>
  );
};

export default ViewUser;
