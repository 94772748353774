import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { Link } from 'react-router-dom';
import { MdOutlineBlock, MdOutlineClose } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { FaRegEye } from 'react-icons/fa';
import { toast } from 'sonner';
import Loader from '../Loader';

const ListUsers = () => {

  const [user, setUser] = useState([]);
  const [searchUserEmail, setSearchUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)
  const [kyc_pending_verification_only, setKyc_pending_verification_only] = useState('');

  // Fetch All users
  const getUser = useCallback(async (offset, limit, email) => {
    try {
      let query = `?offset=${offset}&limit=${limit}`

      if (email) {
        query += `&email=${email}`
      }

      if (kyc_pending_verification_only) {
        query += `&kyc_pending_verification_only=${kyc_pending_verification_only}`
      }

      const response = await axios.get(`/users/${query}`);
      setUser(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred!');
      }
    }
  }, [kyc_pending_verification_only]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(true);
      getUser((currentPage - 1) * recordsPerPage, recordsPerPage, searchUserEmail);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [currentPage, recordsPerPage, searchUserEmail, getUser, kyc_pending_verification_only]);


  // search user by email
  const handleSearchChange = (e) => {
    setSearchUserEmail(e.target.value);
    setCurrentPage(1);
  };

  // enable a user
  const enableUser = async (id) => {
    const confirmed = window.confirm('Are you sure you want to enable this user');
    if (confirmed) {
      try {
        await axios.put(`/users/${id}/enable`);
        toast.success('User Enabled');
        getUser((currentPage - 1) * recordsPerPage, recordsPerPage, searchUserEmail);
      } catch (error) {
        toast.error('Failed to enable user');
        console.log(error);
      }
    }
  };

  // disable a user
  const disableUser = async (id) => {
    const confirmed = window.confirm('Are you sure you want to disable this user');
    if (confirmed) {
      try {
        await axios.put(`/users/${id}/disable`);
        toast.info('User Disabled');
        getUser((currentPage - 1) * recordsPerPage, recordsPerPage, searchUserEmail);
      } catch (error) {
        toast.error('Failed to disable user');
        console.log(error);
      }
    }
  };


  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4 xl:w-[78vw] 2xl:w-full'>
        <div className='flex flex-wrap items-center justify-between py-3'>
          <Link to='/app/adduser' className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg'>Add User</Link>
          <h5 className='text-[#6735b7] font-bold'>Showing {count} Users</h5>
          <div className='py-2 flex flex-wrap items-center gap-2'>
            <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={kyc_pending_verification_only}
                onChange={(e) => setKyc_pending_verification_only(e.target.value)}
              >
                <option value=''>Pending Verification</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            <form>
              <label htmlFor='search'><span className='hidden'>Search</span>
                <input
                  type='search'
                  id='search'
                  className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 placeholder-slate-400 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                  required
                  placeholder='Search email'
                  value={searchUserEmail}
                  onChange={handleSearchChange}
                />
              </label>
            </form>
          </div>
        </div>
        {loading ? (
          <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
            <Loader />
          </div>
        ) : error ? (
          <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
            <div className='grid place-items-center text-red-600 p-4'>
              <h3><MdOutlineBlock /></h3>
              <span>{error}</span>
            </div>
          </div>
        ) : (
          <div className='overflow-x-auto rounded-lg'>
            {user.length > 0
              ? (
                <table className='w-full text-left table-auto'>
                  <thead>
                    <tr className='border-b border-slate-500'>
                      <th className='p-2'>No</th>
                      <th className='p-2'>Name</th>
                      <th className='p-2'>Email</th>
                      <th className='p-2'>Phone Number</th>
                      <th className='p-2'>Role</th>
                      <th className='p-2'>Status</th>
                      <th className='p-2'>Created At</th>
                      <th className='p-2'>Last Login</th>
                      <th className='p-2'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.map((users) => (
                      <tr key={users.id}>
                        <td className='p-2 '>{users.id}</td>
                        <td className='p-2'>{users.name}</td>
                        <td className='p-2'>{users.email}</td>
                        <td className='p-2'>{users.msisdn}</td>
                        <td className='p-2'>{users.role}</td>
                        <td className='p-2'>
                          {users.is_enabled === true ? (
                            <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Enabled</span>
                          ) : (
                            <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Disabled</span>
                          )}
                        </td>
                        <td className='p-2'>{new Date(users.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                        <td className='p-2'>{users.last_login ? new Date(users.last_login).toISOString().replace('T', ' ').slice(0, 19) : '...' }</td>
                        <td className='p-2 flex space-x-2'>
                          {users.role === 'ADVERTISER' && (
                            <span className='text-blue-600 hover:text-blue-500 text-xl flex items-center justify-center'><Link to={`/app/listusers/${users.id}`}><FaRegEye /></Link></span>
                          )}
                          {users.is_enabled === true ? (
                            <button onClick={() => disableUser(users.id)} className='text-orange-600 hover:text-orange-500 flex items-center'><span><MdOutlineClose /></span>Disable</button>
                          ) : (
                            <button onClick={() => enableUser(users.id)} className='text-green-600 hover:text-green-500 flex items-center'><span><IoCheckmarkSharp /></span>Enable</button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center h-[70vh]'>
                  <div className='grid place-items-center p-4'>
                    <h3><MdOutlineBlock /></h3>
                    <h4>No Data</h4>
                  </div>
                </div>
              )}
          </div>
        )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
