import React, { useState } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const CreateCounty = () => {
  
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // creating country
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/countries/',
        { name }
      );
      toast.success('Country added successfully!');
      navigate('/app/listcountries');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else {
        toast.error('Error adding country.');
      }
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Create Country</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='name'>Country Name
              <input
                type='text'
                required
                placeholder='Country'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#6735b7] text-white px-5 py-1 w-full hover:bg-[#8144E5]'>{loading ? 'Please wait...' : 'Create Country'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCounty;
