import React, { useState,useEffect } from 'react'
import axios from '../api/api';
import { FaDollarSign } from 'react-icons/fa';

const Home = () => {
  // getting current user
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user;

  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    const getPricing = async () => {
      try {
        const response = await axios.get('/billing/pricing');
        setPricing(response.data);
      } catch (error) {
        console.error('Error fetching pricing:', error);
      }
    };
    getPricing();
  }, []);

  return (
    <main>
      <div className='mx-auto p-4'>
        <div className='bg-[#331a5b] text-white p-4 rounded-lg'>
          <h2>Welcome {user.name}</h2>
          <p>To get started use the navigation on your left.</p>
        </div>

        {/* pricing */}
        <div className='bg-white rounded-md p-4 mt-4 w-full lg:w-1/2 xl:w-1/3 2xl:w-1/5 '>
          <div className='flex flex-wrap items-center p-4 gap-3'>
            <div className='text-4xl bg-[#8144E5] text-white p-4 rounded-md'>
              <span >
                <FaDollarSign />
              </span>
            </div>
            <div>
              <h4 className='text-center font-bold text-[#8144E5]'><u>Advert Pricing</u></h4>
              <div className='mt-2 block'>
                <div>
                  <b>Cost Per Click : </b> 
                  {pricing.cost_per_click}
                </div>
                <div>
                  <b>Cost Per Mille Image : </b> 
                  {pricing.cost_per_mille_image}
                </div>
                <div>
                  <b>Cost Per Mille Video : </b> 
                  {pricing.cost_per_mille_video}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <br />
        </div>
      </div>
    </main>
  );
};

export default Home;
