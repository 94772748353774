import React, { useState } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

const DebitWallet = () => {
  const [transaction_amount, setTransaction_amount] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();

  // debit a wallet
  const handleDebit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/wallets/${id}/debit`,
        { transaction_amount, description }
      );
      toast.success('Wallet Debited');
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.detail);
    }
  };
  return (
    <div>
      <div>
        <form onSubmit={handleDebit}>
          <div>
            <label htmlFor='amount'>Transaction Amount
              <input
                type='number'
                required
                placeholder='Amount'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={transaction_amount}
                onChange={(e) => setTransaction_amount(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor='desctiption'>Description
              <input
                type='text'
                required
                placeholder='Decription'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>
          <div className='py-3'>
            <button className='bg-blue-600 text-white px-4 py-1 rounded-md'>Debit Wallet</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DebitWallet;
