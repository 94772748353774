import React, { useState } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, Link } from 'react-router-dom';

const AddUser = () => {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [role, setRole] = useState('');
  const [accepted_terms, setAccepted_terms] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Add new User
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    setLoading(true);
    try {
      await axios.post('/users/',
        { name, email, msisdn, role, accepted_terms, password }
      );
      toast.success('User added successfully!');
      navigate('/app/listusers');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else {
        toast.error('Error adding user.');
      }
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-full xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Add New User.</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='fullname'>Full Name
              <input
                type='text'
                required
                placeholder='Full Names'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Email'>Email
              <input
                type='email'
                required
                placeholder='example@gmail.com'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Phone'>Phone
              <input
                type='number'
                required
                placeholder='0700000000'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='role'>User Role
              <select
                name='role' id='role'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                required
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value=''>Choose Role</option>
                <option value='ADMIN'>Admin</option>
                <option value='OPERATOR'>Operator</option>
                <option value='ADVERTISER'>Advertisor</option>
                <option value='FINANCE'>Finance</option>
                <option value='READ_ONLY'>Read Only</option>
              </select>
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Password'>Password
              <input
                type='password'
                required
                placeholder='Password'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='confirm Password'>Confirm Password
              <input
                type='password'
                required
                placeholder='Confirm Password'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 flex items-center space-x-1'>
            <label htmlFor="checkbox"><span className='hidden'>Terms&condition</span></label>
            <input type="checkbox" className='items-center' value={accepted_terms} onChange={(e) => setAccepted_terms(e.target.value)} required />
            <Link to='/terms&condition' className='text-blue-600 hover:text-blue-800'><u>I accept the Terms & Conditions</u></Link>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#6735b7] text-white px-5 py-1 w-full hover:bg-[#8144E5]'>{loading ? 'Please wait...' : 'Add User'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
