import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate,useParams } from 'react-router-dom';

const CreateOrgAdvert = () => {

  const { id } = useParams();
  const [advert_name, setAdvert_name] = useState('');
  const [product_name, setProduct_name] = useState('');
  const [product_price, setProduct_price] = useState('');
  const [product_description, setProduct_description] = useState('');
  const [objective, setObjective] = useState('');
  const [category_id, setCategory_id] = useState('');
  const [sub_category_id, setSub_category_id] = useState('');
  const [is_clickable, setIs_clickable] = useState('true');
  const [click_url, setClick_url] = useState('');
  const [click_action_type, setClick_action_type] = useState('');
  const [organization_id, setOrganization_id] = useState(id);
  const [media_type, setMedia_type] = useState('IMAGE');
  const [media_duration_seconds, setMedia_duration_seconds] = useState(0);
  const [min_play_duration_seconds, setMin_play_duration_seconds] = useState(5);


  const [listCategory, setListCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  // Fetch All categories
  const getCategories = async () => {
    try {
      const response = await axios.get('/adverts/categories/');
      setListCategory(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  // Fetch sub-categories when a category is selected
  useEffect(() => {
    if (category_id) {
      const getSubCategories = async () => {
        try {
          const response = await axios.get(`/adverts/categories/?parent_category_id=${category_id}`);
          setSelectedSubCategory(response.data.items);
        } catch (error) {
          console.log(error);
        }
      };
      getSubCategories();
    } else {
      setSelectedSubCategory([]);
    }
  }, [category_id]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('/adverts/ads/',
        { advert_name, product_name, product_price, product_description, objective, organization_id, category_id, sub_category_id, media_type, media_duration_seconds, min_play_duration_seconds, is_clickable,click_action_type, click_url }
      );
      toast.success('Advert added successfully!');
      if (response.status === 201) {
        const { id } = response.data;
        navigate(`/app/updateadvertmedia/${id}`);
      }
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.')
      } else {
        toast.error('Error adding advert.');
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Create New Advert.</h3>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 gap-3 md:grid-cols-1 lg:grid-cols-2 mt-2'>
            <div>
              <label htmlFor='name'>Advert Name
                <input
                  type='text'
                  required
                  placeholder='Advert Name'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={advert_name}
                  onChange={(e) => setAdvert_name(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label htmlFor='product'>Product Name
                <input
                  type='text'
                  required
                  placeholder='Product Name'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={product_name}
                  onChange={(e) => setProduct_name(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label htmlFor='id'>Product Price
                <input
                  type='number'
                  placeholder='Product Price'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={product_price}
                  onChange={(e) => setProduct_price(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label htmlFor='product description'>Product Description
                <input
                  type='text'
                  placeholder='Product Description'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={product_description}
                  onChange={(e) => setProduct_description(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2 hidden'>
              <label htmlFor='id'>Organisation Id
                <input
                  type='number'
                  required
                  disabled
                  placeholder='Organisation Id'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={organization_id}
                  onChange={(e) => setOrganization_id(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label htmlFor='category'>Category
                <select
                  name='category' id='category'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={category_id}
                  onChange={(e) => {
                    setCategory_id(e.target.value);
                    setSub_category_id('');
                  }}
                >
                  <option value=''>Select Category</option>
                  {listCategory.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </label>
            </div> 
            <div>
              <label htmlFor='objective'>Objective
                <select
                  name='' id=''
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={objective}
                  onChange={(e) => setObjective(e.target.value)}
                >
                  <option value=''>Choose one</option>
                  <option value='SELL_PRODUCTS'>Sell Products</option>
                  <option value='CREATE_BRAND_AWARENESS'>Create Brand Awareness</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor='subcategory'>Sub Category
                <select
                  name='subcategory' id='subcategory'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={sub_category_id}
                  onChange={(e) => setSub_category_id(e.target.value)}
                >
                <option value=''>Select Sub Category</option>
                {selectedSubCategory.length > 0 ? (
                    selectedSubCategory.map(subCategory => (
                      <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                    ))
                  ) : (
                    <option value='' disabled>No sub category available</option>
                  )}
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="media">Media type
                <select 
                  name="" id=""
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={media_type}
                  onChange={(e) => setMedia_type(e.target.value)}
                >
                  <option value="">Media Type</option>
                  <option value="IMAGE">Image</option>
                  <option value="VIDEO">Video</option>
                  <option value="GIF">Gif</option>
                </select>
              </label>
            </div>
            {media_type === 'video' &&
              <div>
                <label htmlFor='id'>Media Duration
                  <input
                    type='number'
                    placeholder='Media Duration'
                    className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                    value={media_duration_seconds}
                    onChange={(e) => setMedia_duration_seconds(e.target.value)}
                  />
                </label>
              </div>
            }
            <div>
              <label htmlFor='id'>Mimimum advert play duration
                <input
                  type='number'
                  placeholder='Play Duration'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={min_play_duration_seconds}
                  onChange={(e) => setMin_play_duration_seconds(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label htmlFor="media">Click action type
                <select 
                  name="" id=""
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={click_action_type}
                  onChange={(e) => setClick_action_type(e.target.value)}
                >
                  <option value="">Click Action</option>
                  <option value="NO_ACTION">No Action</option>
                  <option value="OPEN_URL">Open Url</option>
                  <option value="CALL">Call</option>
                  <option value="SMS">SMS</option>
                  <option value="USSD">USSD</option>
                  <option value="WHATSAPP">WhatsApp</option>
                  <option value="EMAIL">Email</option>
                </select>
              </label>
            </div>
            <div className='mt-2 hidden'>
              <h6>Have product website link?</h6>
              <div className='flex items-center space-x-4'>
                <div>
                  <input
                    type='radio'
                    id='link1'
                    name='link'
                    value='true'
                    checked={is_clickable === 'true'}
                    onChange={(e) => setIs_clickable(e.target.value)}
                  />
                  <label htmlFor='link1'>Yes</label><br />
                </div>
                <div>
                  <input
                    type='radio'
                    id='link2'
                    name='link'
                    value='false'
                    checked={is_clickable === 'false'}
                    onChange={(e) => {
                      setIs_clickable(e.target.value);
                      setClick_url('');
                    }}
                  />
                  <label htmlFor='link2'>No</label><br />
                </div>
              </div>
            </div>
            {is_clickable === 'true' && (
              <div>
                <label htmlFor='id'>Click action link
                  <input
                    type='text'
                    placeholder='URL/Phone-number/USSD/Email'
                    className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                    value={click_url}
                    onChange={(e) => setClick_url(e.target.value)}
                  />
                </label>
              </div>
            )}
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Please wait...' : 'Create Advert'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrgAdvert;
