import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateOrgDocument = () => {
  const [media_file, setMedia_file] = useState(null);
  const [orgId, setOrgId] = useState('')
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFileChange = (e) => {
    setMedia_file(e.target.files[0]);
  };

  // getting media by id
  useEffect(() => {
    const getMediaById = async () => {
      try {
        const response = await axios.get(`/kyc-documents/organization-doc/${id}`);
        setMedia_file(response.data);
        setOrgId(response.data.subject_organization_id);
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };
    getMediaById();
  }, [id]);


  // updating media file
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.put(`/kyc-documents/${id}/media`, { media_file }, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success('Media updated');
      setTimeout(() => {
        navigate(`/app/vieworganisation/${orgId}`)
      }, 1000);
    } catch (error) {
      console.error(error?.response);
      toast.error('Failed to update media');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Update Organisation Document.</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='media'>Upload Document here:
              <input
                type='file'
                id='media'
                required
                name='media'
                placeholder='media type'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Updating...' : 'Update Document'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOrgDocument;
