import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';

const CreateCampaign = () => {

  const ageBands = {
    under_18: 'UNDER_18',
    _18_to_24: '18_TO_24',
    _25_to_34: '25_TO_34',
    _35_to_44: '35_TO_44',
    _45_to_54: '45_TO_54',
    _55_to_64: '55_TO_64',
    _65_plus: '65+',
    all: 'ALL'
  };

  const { id } = useParams();
  const [name, setName] = useState('');
  const [advert_id, setAdvert_id] = useState(id);
  const [start_date, setStart_date] = useState('');
  const [end_date, setEnd_date] = useState('');
  const [budget, setBudget] = useState('');
  const [target_gender, setTarget_gender] = useState('');
  const [target_impressions, setTarget_impressions] = useState('');
  const [target_clicks, setTarget_clicks] = useState('');
  const [target_reach, setTarget_reach] = useState('');
  const [target_age_bands, setTarget_age_bands] = useState(ageBands.all);
  const [target_country_id, setTarget_country_id] = useState('');
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false)

  // Fetch All countries
  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await axios.get('/countries/');
        setCountry(response.data.items);
      } catch (error) {
        console.log(error);
      }
    };
    getCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.post('/advert-campaigns/',
        { name, advert_id, start_date: new Date(start_date).toISOString(), end_date: new Date(end_date).toISOString(), budget ,target_gender, target_country_id,
          target_impressions, target_clicks, target_reach, target_age_bands: [{ age_band: target_age_bands }]
        }
      );
      toast.success('Campaign added successfully!');
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error('Network error! Check your connection.')
      } else if (error.response.status === 400) {
        toast.error(error.response.data.detail)
      } else {
        toast.error('Error creating campaign!')
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className='mx-auto p-4'>
      <h3 className='text-xl text-center font-bold text-[#8144E5]'>Add New Campaign.</h3>
      <form onSubmit={handleSubmit}>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2'>
          <div className='p-2'>
            <label htmlFor='name'>Campaign Name
              <input
                type='text'
                required
                placeholder='Name'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2 hidden'>
            <label htmlFor='id'>advertid
              <input
                type='number'
                required
                placeholder='Advert Id'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={advert_id}
                onChange={(e) => setAdvert_id(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Budget
              <input
                type='number'
                required
                placeholder='Budget'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Start Date
              <input
                type='date'
                required
                placeholder='date'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={start_date}
                onChange={(e) => setStart_date(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>End Date
              <input
                type='date'
                required
                placeholder='date'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={end_date}
                onChange={(e) => setEnd_date(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Target Impressions
              <input
                type='number'
                required
                placeholder='Target Impression'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_impressions}
                onChange={(e) => setTarget_impressions(e.target.value)}
              /> 
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Target Clicks
              <input
                type='number'
                required
                placeholder='Target Clicks'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_clicks}
                onChange={(e) => setTarget_clicks(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Target Reach
              <input
                type='number'
                required
                placeholder='Target Reach'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_reach}
                onChange={(e) => setTarget_reach(e.target.value)}
              />
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='id'>Target Gender
              <select
                name="" id=""
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_gender}
                onChange={(e) => setTarget_gender(e.target.value)}
              >
                <option value="">Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="ALL">All</option>
              </select>
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='country'>Target Country
              <select
                name='' id=''
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_country_id}
                onChange={(e) => setTarget_country_id(e.target.value)}
              >
                <option value=''>Choose Country</option>
                {country.map((countries) => (
                  <option key={countries.id} value={countries.id}>{countries.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className='p-2'>
            <label htmlFor='country'>Target Age Bands
              <select
                name='' id=''
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={target_age_bands}
                onChange={(e) => setTarget_age_bands(e.target.value)}
              >
                {Object.values(ageBands).map((ageBand) => (
                  <option key={ageBand} value={ageBand}>{ageBand}</option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className='py-3'>
          <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Please Wait' : 'Create Campaign'}</button>
        </div>
      </form>
    </div>
  );
};

export default CreateCampaign;
