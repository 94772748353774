import React from 'react'

const PlatfotmTerms = () => {
  return (
    <main className='bg-[#331a5b] py-8'>
      <div className="container mx-auto p-6 bg-white rounded-lg w-full xl:w-1/2">
        <h2 className='text-center font-bold text-blue-600'><u>Advertisement Platform Terms and Conditions</u></h2>
        <div className='space-y-4'>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>1. Acceptance of Terms</b></h4>
            <li className='list-disc font-light'>
              By accessing or using the CODEWAVE TECHNOLOGIES LIMITED (the "Platform"), you agree to be bound by these Terms and Conditions ("Terms"). 
              If you do not agree to all of the provisions contained in these Terms, do not access or use the Platform.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>2. Description of the Platform</b></h4>
            <li className='list-disc font-light'>
              The Platform provides a marketplace for advertisers ("Advertisers") to create and display advertisements ("Ads") on various channels, including websites, mobile applications, and social media platforms.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>3. Advertiser Registration and Accounts</b></h4>
            <div>
              <div>
                <span className='font-bold'>3.1 Advertiser Accounts:</span> 
                <li className='list-disc font-light'>Advertisers must register for an account on the Platform to create and manage Ads. Advertiser accounts are subject to approval by the Platform administrator.</li>
              </div>
              <div>
                <span className='font-bold'>3.2 Account Information:</span>
                <li className='list-disc font-light'>Advertisers must provide accurate and complete information when registering for an account, including contact information, payment details, and Ad content.</li>
              </div>
              <div>
                <span className='font-bold'>3.3 Account Security:</span>
                <li className='list-disc font-light'>Advertisers are responsible for maintaining the security of their account login credentials and are liable for any unauthorized use of their account.</li>
              </div>
            </div>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>4. Ad Placement and Content</b></h4>
            <div>
              <div>
                <span className='font-bold'>4.1 Ad Approval: </span>
                <li className='list-disc font-light'>Ads submitted by Advertisers are subject to approval by the Platform administrator. The Platform reserves the right to reject or remove any Ads that violate these Terms or are deemed inappropriate.</li>
              </div>
              <div>
                <span className='font-bold'>4.2 Ad Content: </span>
                <li className='list-disc font-light'>Advertisers are solely responsible for the content of their Ads, including text, images, and links. Ads must comply with all applicable laws, regulations, and industry standards.</li>
              </div>
              <div>
                <span className='font-bold'>4.3 Prohibited Content:</span> 
                <li className='list-disc font-light'>Ads containing illegal, deceptive, or offensive content are strictly prohibited. Prohibited content includes, but is not limited to, content that promotes violence, discrimination, hate speech, or illegal activities.</li>  
              </div>
              <div>
                <span className='font-bold'>4.4</span>
                <li className='list-disc font-light'>Ads placed are either triggered by end of a call, an incoming message or when one turns on their WIFI. Information collected will allow us to target the right customers and show only relevant Ads to the customers.</li>
              </div>
            </div>
          </div>
          <div className='space-y-2 text-justify' >
            <h4 className='underline'><b>5. Payment Terms</b></h4>
            <div>
              <div>
                <span className='font-bold'>5.1 Pricing: </span>
                <li className='list-disc font-light'>Advertisers agree to pay the fees specified by the Platform for the placement of Ads. Pricing may vary based on factors such as Ad placement, targeting criteria, and duration.</li>
              </div>
              <div>
                <span className='font-bold'>5.2 Payment Methods: </span>
                <li className='list-disc font-light'>Advertisers must provide valid payment information and authorize the Platform to charge their account for Ad placements.</li>
              </div>
              <div>
                <span className='font-bold'>5.3 Billing and Invoices:</span> 
                <li className='list-disc font-light'>The Platform will issue invoices to Advertisers for Ad placements, detailing the fees charged and payment due dates.</li>  
              </div>
            </div>
          </div>
          <div className='space-y-2 text-justify' >
            <h4 className='underline'><b>6. Intellectual Property</b></h4>
            <div>
              <div>
                <span className='font-bold'>6.1 Advertiser Content:</span>
                <li className='list-disc font-light'>Advertisers retain ownership of the content they submit for Ads. By submitting content to the Platform, Advertisers grant the Platform a non-exclusive, 
                royalty-free license to use, display, and distribute the content for the purpose of displaying Ads.</li>
              </div>
              <div>
                <span className='font-bold'>6.2 Platform Content: </span>
                <li className='list-disc font-light'>The Platform owns all rights, title, and interest in and to the Platform, including but not limited to logos, trademarks, and software.</li>
              </div>
            </div>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>7. Disclaimer of Warranties</b></h4>
            <li className='list-disc font-light'>
              THE PLATFORM IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE PLATFORM DISCLAIMS ALL WARRANTIES, 
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>8. Limitation of Liability</b></h4>
            <li className='list-disc font-light'>
              IN NO EVENT SHALL THE PLATFORM BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, ARISING OUT OF OR IN CONNECTION
              WITH THE USE OF THE PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>9. Indemnification</b></h4>
            <li className='list-disc font-light'>
              Advertisers agree to indemnify and hold harmless the Platform, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, \
              liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with Advertiser's use of the Platform.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>10. Termination</b></h4>
            <li className='list-disc font-light'>
              The Platform reserves the right to suspend or terminate Advertiser accounts and access to the Platform at any time for violations of these Terms or for any other reason in the Platform's sole discretion.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>11. Governing Law</b></h4>
            <li className='list-disc font-light'>
              These Terms shall be governed by and construed in accordance with the Laws of Kenya, without regard to its conflict of law principles.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4 className='underline'><b>12. Changes to Terms</b></h4>
            <li className='list-disc font-light'>
              The Platform reserves the right to update or modify these Terms at any time without prior notice. Advertisers are responsible for regularly reviewing these Terms for changes.
            </li>
          </div>
          <div className='space-y-2 text-justify'>
            <h4  className='underline'><b>13. Contact Information</b></h4>
            <li className='list-disc font-light'>
              If you have any questions or concerns about these Terms, please contact us at [info@codewavetechnology.com]
            </li>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PlatfotmTerms
