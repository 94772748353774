import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { Link } from 'react-router-dom';
import { MdOutlineBlock, MdOutlineClose } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { FaRegEye } from 'react-icons/fa';
import { toast } from 'sonner';
import Loader from '../Loader';

const ListOrganisations = () => {

  const [organisation, setOrganisation] = useState([]);
  const [searchOrganisation, setSearchOrganisation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)
  const [pending_verification_only, setPending_verification_only] = useState('');


  // Fetch All organisation
  const getorganisation = useCallback(async (offset, limit, name) => {
    try {
      let query = `?offset=${offset}&limit=${limit}`
      if (name) {
        query += `&name=${name}`
      }

      if (pending_verification_only) {
        query += `&pending_verification_only=${pending_verification_only}`
      }

      const response = await axios.get(`/organizations/${query}`);
      setOrganisation(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [pending_verification_only]);


  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(true);
      getorganisation((currentPage - 1) * recordsPerPage, recordsPerPage, searchOrganisation);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [currentPage, recordsPerPage, searchOrganisation, getorganisation, pending_verification_only]);

  // search organisation with organisation name
  const handleSearchChange = (e) => {
    setSearchOrganisation(e.target.value);
    setCurrentPage(1);
  };

  // verify an organisation
  const verifyOrganisation = async (id) => {
    const confirmed = window.confirm('Are you sure you want to verify this organisation');
    if (confirmed) {
      try {
        await axios.put(`/organizations/${id}/verify`);
        toast.success('Organisation verified');
        getorganisation((currentPage - 1) * recordsPerPage, recordsPerPage, searchOrganisation);
      } catch (error) {
        toast.error('Failed to verify Organisation');
        console.log(error);
      }
    }
  };

  // reject an organisation
  const rejectOrganisation = async (id) => {
    const confirmed = window.confirm('Are you sure you want to reject this organisation');
    if (confirmed) {
      try {
        await axios.put(`/organizations/${id}/reject-verification`);
        toast.success('Organisation rejeced');
        getorganisation((currentPage - 1) * recordsPerPage, recordsPerPage, searchOrganisation);
      } catch (error) {
        toast.error('Failed to verify Organisation');
        console.log(error);
      }
    }
  };

  // suspend an organisation
  const suspendOrganisation = async (id) => {
    const confirmed = window.confirm('Are you sure you want to suspend this organisation');
    if (confirmed) {
      try {
        await axios.put(`/organizations/${id}/suspend`);
        toast.success('Organisation suspended');
        getorganisation((currentPage - 1) * recordsPerPage, recordsPerPage, searchOrganisation);
      } catch (error) {
        toast.error('Failed to suspend organisation');
        console.log(error);
      }
    }
  };

  // unsuspend an organisation
  const unsuspendOrganisation = async (id) => {
    const confirmed = window.confirm('Are you sure you want to unsuspend this organisation');
    if (confirmed) {
      try {
        await axios.put(`/organizations/${id}/un-suspend`);
        toast.success('Organisation unsuspended');
        getorganisation((currentPage - 1) * recordsPerPage, recordsPerPage, searchOrganisation);
      } catch (error) {
        toast.error('Failed to unsuspend organisation');
        console.log(error);
      }
    }
  };


  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4 xl:w-[78vw] 2xl:w-full'>
        <section className='flex flex-wrap items-center justify-between py-3'>
          <Link to='/app/addorganisation' className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg'>Add organisation</Link>
          <h5 className='text-[#6735b7] font-bold'>Showing {count} organisations</h5>
          <div className='flex flex-wrap items-center gap-2 py-2'>
            <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={pending_verification_only}
                onChange={(e) => setPending_verification_only(e.target.value)}
              >
                <option value=''>Pending Verification Only</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='search'><span className='hidden'>Search</span>
                <input
                  type='search'
                  id='search'
                  className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 placeholder-slate-400 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                  required
                  placeholder='Search Organisation'
                  value={searchOrganisation}
                  onChange={handleSearchChange}
                />
              </label>
            </div>
          </div>
        </section>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {organisation.length > 0
                  ? (
                    <table className='w-full text-left table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Name</th>
                          <th className='py-3 px-6'>Registration Number</th>
                          <th className='py-3 px-6'>Registered Address</th>
                          <th className='py-3 px-6'>Contact</th>
                          <th className='py-3 px-6'>Email</th>
                          <th className='py-3 px-6'>Verification</th>
                          <th className='py-3 px-6'>Status</th>
                          <th className='py-3 px-6'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {organisation.map((organisation) => (
                          <tr key={organisation.id}>
                            <td className='py-2 px-6'>{organisation.id}</td>
                            <td className='py-2 px-6'><Link to={`/app/vieworganisation/${organisation.id}`}>{organisation.name}</Link></td>
                            <td className='py-2 px-6'>{organisation.registration_number}</td>
                            <td className='py-2 px-6'>{organisation.registered_address}</td>
                            <td className='py-2 px-6'>{organisation.contact_msisdn}</td>
                            <td className='py-2 px-6'>{organisation.contact_email}</td>
                            <td className='py-2 px-6'>
                              {organisation.is_verified === true ? (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Verified</span>
                              ) : (
                                <span className='bg-[#D9E7FA] text-[#215CB1] px-4 py-1 font-bold rounded-full'>Pending</span>
                              )}
                            </td>
                            <td className='py-2 px-6'>
                              {organisation.is_suspended === true ? (
                                <span className='bg-[#E6E8EC] text-[#5D6998] px-4 py-1 font-bold rounded-full'>Suspended</span>
                              ) : (
                                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-4 py-1 rounded-full'>Active</span>
                              )}
                            </td>
                            <td className='py-2 px-6 flex items-center space-x-2'>
                              <div className=''>
                                <span className='text-blue-600 text-xl'><Link to={`/app/vieworganisation/${organisation.id}`}><FaRegEye /></Link></span>
                              </div>
                              <div className='grid'>
                                {organisation.is_verified === true ? (
                                  <button onClick={() => rejectOrganisation(organisation.id)} className='text-red-600 hover:text-red-900 flex items-center'><span><MdOutlineClose /></span>Reject</button>
                                ) : (
                                  <button onClick={() => verifyOrganisation(organisation.id)} className='text-green-600 hover:text-green-900 flex items-center'><span><IoCheckmarkSharp /></span>Verify</button>
                                )}
                                {organisation.is_suspended === true ? (
                                  <button onClick={() => unsuspendOrganisation(organisation.id)} className='text-purple-600 hover:text-purple-900 flex items-center'><span><IoCheckmarkSharp /></span>UnSuspend</button>
                                ) : (
                                  <button onClick={() => suspendOrganisation(organisation.id)} className='text-orange-600 hover:text-orange-900 flex items-center'><span><MdOutlineClose /></span>Suspend</button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center h-[70vh]'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListOrganisations;
