import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';

const OrganisationCamapignMetrics = () => {

  const [orgmetrics, setOrgmetrics] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);

  // Fetch All metrics
  const getinvoices = useCallback(async () => {
    try {
      const offset = (currentPage - 1) * recordsPerPage;
      const response = await axios.get(`/invoices/campaign-metrics/${id}?offset=${offset}&limit=${recordsPerPage}`);
      setOrgmetrics(response.data.items);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [id,currentPage, recordsPerPage]);

  useEffect(() => {
    getinvoices();
  }, [getinvoices]);

  return (
    <section className='my-4 bg-white p-4 rounded-lg w-[92vw] xl:w-[78vw] 2xl:w-full'>
      <div className='flex flex-wrap items-center justify-between py-3 gap-2'>
        <h5 className='text-[#6735b7] font-bold'>Showing {orgmetrics.length} Campaign metrics</h5>
      </div>
      {loading
        ? (
          <div className='flex items-center justify-center bg-[#f2ecfc] p-4'>
            <Loader />
          </div>
          )
        : error
          ? (
            <div className='bg-[#f2ecfc] grid place-items-center'>
              <div className='grid place-items-center text-red-600 p-4'>
                <h3><MdOutlineBlock /></h3>
                <span>{error}</span>
              </div>
            </div>
            )
          : (
            <div className='overflow-x-auto'>
              {orgmetrics.length > 0
                ? (
                  <table className='w-full text-justify table-auto'>
                    <thead>
                      <tr className='border-b border-slate-500'>
                        <th className='py-3 px-6'>No</th>
                        <th className='py-3 px-6'>Camapign Id</th>
                        <th className='py-3 px-6'>Advert Id</th>
                        <th className='py-3 px-6'>Organization Id</th>
                        <th className='py-3 px-6'>Clicks</th>
                        <th className='py-3 px-6'>Views</th>
                        <th className='py-3 px-6'>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgmetrics.map((metric) => (
                        <tr key={metric.id}>
                          <td className='py-2 px-6'>{metric.id}</td>
                          <td className='py-2 px-6'>{metric.campaign_id}</td>
                          <td className='py-2 px-6'>{metric.advert_id}</td>
                          <td className='py-2 px-6'>{metric.organization_id}</td>
                          <td className='py-2 px-6'>{metric.clicks}</td>
                          <td className='py-2 px-6'>{metric.views}</td>
                          <td className='py-2 px-6'>{new Date(metric.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  )
                : (
                  <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center'>
                    <div className='grid place-items-center p-4'>
                      <h3><MdOutlineBlock /></h3>
                      <h4>No Data</h4>
                    </div>
                  </div>
                  )}
            </div>
            )}
      <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
        <div>
          <span className='mr-2'>Records per page:</span>
          <select
            className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
            value={recordsPerPage}
            onChange={(e) => {
              setRecordsPerPage(parseInt(e.target.value, 10));
              setCurrentPage(1);
            }}
          >
            <option value='5'>5</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </div>
        <Pagination
          nPages={Math.ceil(orgmetrics)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  )
}

export default OrganisationCamapignMetrics
