import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineCancel, MdOutlineDashboard, MdAppSettingsAlt, MdAccountBalance, MdRecommend } from 'react-icons/md';
import { FaUsers, FaFlag, FaUser } from 'react-icons/fa';
import { RiAdvertisementFill, RiAdvertisementLine, RiCustomerServiceFill } from 'react-icons/ri';
import { IoDocuments } from 'react-icons/io5';
import { useStateContext } from '../Context/ContextProvider';
import 'tw-elements';
import AdvertisorNav from './AdvertisorNav';
import axios from '../../api/api'

const SideBar = () => {
  const { activeMenu, setActiveMenu } = useStateContext();
  const sidebarRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close sidebar when clicking outside of it on mobile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setActiveMenu(false);
      }
    };

    if (isMobile && activeMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeMenu, isMobile, setActiveMenu]);

  // getting current user
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user;

  const [userProfile, setUserProfile] = useState({});

  // getting Advertiser KYC profile
  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get('/users/me');
        setUserProfile(response.data.kyc_profile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserById();
  }, []);

  return (
    <main ref={sidebarRef} className='bg-[#190d2d] text-white h-screen md:overflow-hidden relative overflow-auto z-10'>
      {activeMenu && (
        <>
          <span className='p-3 xl:p-2 2xl:p-4 flex justify-around items-center'>
            <span className="title text-2xl 2xl:text-3xl">Street Smart Ads</span>
            <div className='text-2xl mt-3 md:hidden hover:cursor-pointer'>
              <button onClick={() => setActiveMenu(!activeMenu)}><MdOutlineCancel /></button>
            </div>
          </span>
          <hr />
          <nav>
            {user.role === 'ADVERTISER' ? (
              user.role === 'ADVERTISER' && userProfile.verification_status === 'VERIFIED' && (<AdvertisorNav />)
            ) : (
              <div id='sidenavSecExample'>
                <ul className='relative px-0.5'>
                  <li className='relative'>
                    <Link to='/app/dashboard' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>
                      <span className='w-4 h-4 mr-2'><MdOutlineDashboard /></span>
                      <span>DashBoard</span>
                    </Link>
                  </li>
                  <li className='relative' id='sidenavSecEx1'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx1' aria-expanded='false' aria-controls='collapseSidenavSecEx1'>
                      <span className='w-4 h-4 mr-2'><FaUsers /></span>
                      <span>Users</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx1' aria-labelledby='sidenavSecEx1' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listusers' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Users</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/pendingusers' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Pending Verification</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/adduser' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Add User</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx8'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx8' aria-expanded='false' aria-controls='collapseSidenavSecEx8'>
                      <span className='w-4 h-4 mr-2'><MdAccountBalance /></span>
                      <span>Organisations</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx8' aria-labelledby='sidenavSecEx8' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listorganisations' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Organisations</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/myorganisations' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>My Organisations</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/addorganisation' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Add Organisation</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx2'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx2' aria-expanded='false' aria-controls='collapseSidenavSecEx2'>
                      <span className='w-4 h-4 mr-2'><RiAdvertisementFill /></span>
                      <span>Adverts</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx2' aria-labelledby='sidenavSecEx2' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listadvert' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Adverts</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/pendingadverts' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Pending Adverts</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/listcategories' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Categories</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/addcategory' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Add Category</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx5'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx5' aria-expanded='false' aria-controls='collapseSidenavSecEx5'>
                      <span className='w-4 h-4 mr-2'><RiAdvertisementLine /></span>
                      <span>Advert Campaign</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx5' aria-labelledby='sidenavSecEx5' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listcampaign' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Campaigns</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx11'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx11' aria-expanded='false' aria-controls='collapseSidenavSecEx11'>
                      <span className='w-4 h-4 mr-2'><IoDocuments /></span>
                      <span>KYC Documents</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx11' aria-labelledby='sidenavSecEx11' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/alldocuments' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List All Documents</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx4'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx4' aria-expanded='false' aria-controls='collapseSidenavSecEx4'>
                      <span className='w-4 h-4 mr-2'><FaFlag /></span>
                      <span>Countries & Currencies</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx4' aria-labelledby='sidenavSecEx4' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listcountries' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Countries</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/createcounty' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Create Country</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/listcurrencies' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Currencies</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/createcurrency' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Create Currency</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx6'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx6' aria-expanded='false' aria-controls='collapseSidenavSecEx6'>
                      <span className='w-4 h-4 mr-2'><RiCustomerServiceFill /></span>
                      <span>Ad Consumer Customers</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx6' aria-labelledby='sidenavSecEx6' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listcustomer' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Customers</Link>
                      </li>
                    </ul>
                  </li>                       
                  <li className='relative' id='sidenavSecEx9'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx9' aria-expanded='false' aria-controls='collapseSidenavSecEx9'>
                      <span className='w-4 h-4 mr-2'><FaUser /></span>
                      <span>User Ad Interaction</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx9' aria-labelledby='sidenavSecEx9' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listadviews' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Add Views</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/listabusereport' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Abuse Reports</Link>
                      </li>
                      <li className='relative'>
                        <Link to='/app/listadvertlikes' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Advert Likes</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx10'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx10' aria-expanded='false' aria-controls='collapseSidenavSecEx10'>
                      <span className='w-4 h-4 mr-2'><MdRecommend /></span>
                      <span>Advert Recomendations</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx10' aria-labelledby='sidenavSecEx10' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listadvertrecommendation' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Advert Recommendations</Link>
                      </li>
                    </ul>
                  </li>
                  <li className='relative' id='sidenavSecEx7'>
                    <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx7' aria-expanded='false' aria-controls='collapseSidenavSecEx7'>
                      <span className='w-4 h-4 mr-2'><MdAppSettingsAlt /></span>
                      <span>Recommender Apps</span>
                      <span className='w-4 h-4 ml-auto text-xl'><MdOutlineKeyboardArrowDown /></span>
                    </Link>
                    <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx7' aria-labelledby='sidenavSecEx7' data-bs-parent='#sidenavSecExample'>
                      <li className='relative'>
                        <Link to='/app/listrecommenderapp' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Recommender Apps</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        </>
      )}
    </main>
  );
};

export default SideBar;
