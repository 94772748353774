import React from 'react'

const CustomerTerms = () => {
  return (
    <main className='bg-[#331a5b] py-8'>
      <section className="container mx-auto p-6 bg-white rounded-lg w-full xl:w-1/2">
        <div className='space-y-2'>
          <span className='text-center font-bold text-blue-600 text-lg xl:text-3xl'><u>TERMS AND CONDITIONS FOR AD PLATFORM SERVICE</u></span>
          <p>
            These terms and conditions ("Terms") govern your use of the Ad Platform Service ("Service")
            provided by Codewave ("we," "us," or "our"). By accessing or using the Service, you agree to be
            bound by these Terms. If you do not agree to these Terms, please do not access or use the Service.
          </p>
        </div>
        <br />
        <h4 className='text-blue-700 font-bold'>IT IS AGREED AS FOLLOWS:</h4>
        <br />
        <div className='space-y-4'>
          <div className='space-y-1 text-justify'>
            <div>
              <h4 className='underline text-start'><b>1. DEFINITIONS AND INTERPRETATION</b></h4>
              <span><b>1.1 Definitions</b></span>
              <p>For the purposes of this Agreement and the preamble above, unless the context requires otherwise:</p>
            </div>
            <li className='list-disc font-light'>Acceptable Use Restrictions has the meaning given to it in clause 5;</li>
            <li className='list-disc font-light'>Ad Platform services means Codewave’s services</li>
            <li className='list-disc font-light'>Agreement means this Agreement;</li>
            <li className='list-disc font-light'>Equipment means phone, tablet or any other equipment which when used, enables you to access the Ad Platform services.</li>
            <li className='list-disc font-light'>Force Majeure <br />
              Means events, circumstances or causes beyond its reasonable control of Codewave making Ad
              Platform service performance of its obligations inadvisable, commercially impracticable, illegal,
              or impossible, including but not limited to acts of God, war, strikes or labour disputes, embargoes
              or government orders; <br />
              Encumbrance includes any charge (whether legal or equitable), lien, option, security interest,
              restrictive covenant, pledge, assignment, title retention, trust arrangement or other restriction of
              any kind or other encumbrance securing or any right conferring a priority of payment in respect
              of any obligation of any person;
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <div>
              <h4 className='underline text-start'><b>1.2 Interpretation</b></h4>
              <span><b>1.1 Definitions</b></span>
            </div>
            <li className='list-disc font-light'>1.2.1 In addition to the definitions in clause 1.1, unless the context requires otherwise: the singular shall include the plural and vice versa;</li>
            <li className='list-disc font-light'>1.2.2 A reference to any one gender, whether masculine, feminine or neuter, includes the other two.</li>
            <li className='list-disc font-light'>1.2.3 All the headings and sub-headings in this Agreement are for convenience only and are not to be taken into account for the purposes of interpretation of this Agreement.</li>
            <li className='list-disc font-light'>1.2.4 The recitals and schedules shall be deemed to form part of this Agreement.</li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>2. ACCEPTANCE OF TERMS AND CONDITIONS</b></h4>
            <li className='list-disc font-light'>
              2.1 You must carefully read and understand the Terms and Conditions set out in this
              Agreement and as amended from time to time by the Ad Platform Service (the Terms and
              Conditions) before using the Ad Platform Service.
            </li>
            <li className='list-disc font-light'>
              2.2 By downloading the Terms and Conditions, you agree to comply with and be bound by the
              Terms and Conditions governing the operation of the Ad Platform and you affirm that the Terms
              and Conditions herein are without prejudice to any other right that the Ad Platform Service may
              have with respect to the service in law or otherwise.
            </li>
            <li className='list-disc font-light'>
              2.4 These Terms and Conditions may be amended or varied from time to time and the continued
              use of the Services constitutes your agreement to be bound by the terms of any such amendment
              or variation. Codewave will take all reasonable measures to notify you of any changes.
            </li>
            <li className='list-disc font-light'>
              2.5 From time to time updates may be issued through the website. Depending on the update, you
              may not be able to use the Services until you have downloaded or streamed the latest version of
              the Ad Platform service and accepted any new terms and conditions.
            </li>
            <li className='list-disc font-light'>
              2.6 By using Calls, SMS and WIFI, you consent to us collecting and using technical information
              about the service and related software, hardware and peripherals for Services that are internet-
              based or wireless to improve our products and to provide any Services to you. If you use these
              Services, you consent to us and our affiliates' and licensees' transmission, collection, retention,
              maintenance, processing and use of your data to determine our credit scoring services or to
              improve our Services and/or your experience while using the platform.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <div>
              <h4 className='underline text-start'><b>3. GRANT AND SCOPE OF LICENCE</b></h4>
              <span>
                3.1 In consideration of you agreeing to abide by the terms of this Agreement, we grant
                you a non-transferable, non-exclusive licence to use the Ad Platform Service services on
                your Equipment, subject to these Terms and Conditions. We reserve all other rights.
                Except as expressly set out in this Agreement or as permitted by any local law, you agree:
              </span>
            </div>
            <li className='list-disc font-light'>
              3.1.1 Not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the Ad
              Platform;
            </li>
            <li className='list-disc font-light'>
              3.1.2 not to make alterations to, or modifications of, the whole or any part of the platform,
              or permit the platform or any part of it to be combined with, or become incorporated in,
              any other programs;
            </li>
            <ul className='list-disc font-light'>
              <p>
                3.1.3 not to disassemble, decompile, reverse-engineer or create derivative works based on
                the whole or any part of the Ad platform or attempt to do any such thing except to the
                extent that such actions cannot be prohibited because they are essential for the purposeof achieving inter-operability of the Ad platform with another software program, and
                provided that:
              </p>
              <li className='list-disc font-light'>
                3.1.3.1 The information obtained by you during such activities is not unnecessarily
                disclosed or communicated without our prior written consent to any third party; and is
                not used to create any software that is substantially similar to the Ad Platform;
              </li>
              <li className='list-disc font-light'>
                3.1.3.2 you include our copyright notice on all entire and partial copies you make of the
                Ad platform on any medium; not to provide or otherwise make available the platform in
                whole or in part (including object and source code), in any form to any person without
                prior written consent from us; and <br />
                To comply with all technology control or export laws and regulations that apply to the
                technology used or supported by calls, SMS, location and Wi-Fi or any Service (the
                Technology).
              </li>
            </ul>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>4. Service Description</b></h4>
            <li className='list-disc font-light'>
              The Service provided by Codewave is an advertising platform that utilizes data from calls, SMS,
              location, and Wi-Fi to deliver targeted advertisements to users. Advertisements may be triggered
              by the end of a call, incoming messages, or activation of Wi-Fi.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <div>
              <h4 className='underline text-start'><b>5. Data Collection and Usage</b></h4>
              <span>By using the Service, you consent to the collection of certain data from your device, including but not limited to:</span>
            </div>
            <li className='list-disc font-light'>Calls</li>
            <li className='list-disc font-light'>SMS</li>
            <li className='list-disc font-light'>Location information</li>
            <li className='list-disc font-light'>Wi-Fi network information</li>
            <br />
            <span>We collect this data solely for the purpose of targeting relevant advertisements to you. Your
              personal information will not be shared with third parties without your consent, except as
              required by law.
            </span>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>6. Opt-Out</b></h4>
            <li className='list-disc font-light'>
              You have the option to opt-out of data collection for advertising purposes at any time. You may
              do so by adjusting your device settings or contacting us directly at
              (info@codewavetechnology.com).
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>7. Privacy and Security</b></h4>
            <li className='list-disc font-light'>
              We are committed to protecting your privacy and securing your data. We employ industry-
              standard security measures to prevent unauthorized access, disclosure, alteration, or destruction
              of your information.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>8. Third-Party Services</b></h4>
            <li className='list-disc font-light'>
              The Service may integrate with third-party services or applications. We are not responsible for
              the privacy practices or content of these third-party services. Please review their terms and
              privacy policies before using their services.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>9. Changes to Terms</b></h4>
            <li className='list-disc font-light'>
              We reserve the right to modify or update these Terms at any time. Changes will be effective
              immediately upon posting to the Service. Your continued use of the Service after any such
              changes constitutes your acceptance of the revised Terms.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>10. Contact Us</b></h4>
            <li className='list-disc font-light'>
              If you have any questions or concerns about these Terms or the Service, please contact us at (info@codewavetechnology.com).
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>11. Governing Law</b></h4>
            <li className='list-disc font-light'>
              These Terms shall be governed by and construed in accordance with the laws of the Republic of
              Kenya, without regard to its conflict of law principles.
            </li>
            <li className='list-disc font-light'>
              By using the Service, you acknowledge that you have read, understood, and agree to be bound
              by these Terms. If you do not agree to these Terms, please refrain from using the Service.
            </li>
          </div>
        </div>
      </section>

      <section className="container mx-auto p-6 mt-5 bg-white rounded-lg w-full xl:w-1/2">
        <div className="space-y-2">
          <h3 className='text-center font-bold text-blue-600'><u>Privacy Policy for Codewave Ad Platform Service</u></h3>
          <div>
            <span>Effective Date: 08/05/2024</span>
            <p>
              Codewave ("we", "us", or "our") respects the privacy of its users ("you" or "your"). This Privacy
              Policy explains how we collect, use, disclose, and safeguard your information when you use our
              Ad Platform Service (the "Service"). Please read this policy carefully to understand our policies
              and practices regarding your information and how we will treat it. By using the Service, you agree
              to the collection and use of information in accordance with this policy. If you do not agree with
              our policies and practices, do not use our Service.
            </p>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>1. Information We Collect</b></h4>
            <p>We may collect and use the following types of information when you use our Service:</p>
            <li className='list-disc font-light'>Interaction with the ads shown.</li>
            <li className='list-disc font-light'>Interactions with ads in the app.</li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>2. How We Use Your Information</b></h4>
            <p>We use the information we collect to:</p>
            <li className='list-disc font-light'>We use the information we collect to:</li>
            <li className='list-disc font-light'>Improve, personalize, and expand our Service</li>
            <li className='list-disc font-light'>Understand and analyze how you use our Service</li>
            <li className='list-disc font-light'>Develop new products, services, features, and functionality</li>
            <li className='list-disc font-light'>
              Communicate with you, either directly or through one of our partners, including for
              customer service, to provide you with updates and other information relating to the
              service, and for marketing and promotional purposes.
            </li>
            <li className='list-disc font-light'>Send you emails</li>
            <li className='list-disc font-light'>Find and prevent fraud</li>
            <li className='list-disc font-light'>Target advertisements to you more effectively by understanding your preferences and interactions with various ad formats</li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>3. How We Share Your Information</b></h4>
            <p>We may share the information we collect in various ways, including the following:</p>
            <li className='list-disc font-light'>
              <b>Vendors and Service Providers:</b> We may share your information with third-party
              vendors and service providers that provide services on our behalf, such as helping toprovide our Service, for promotional and/or marketing purposes, and to provide you
              with information relevant to you such as product advertisements.
            </li>
            <li className='list-disc font-light'>
              <b>As Required By Law:</b> We may share your information if required to do so by law or in
              onse to valid requests by public authorities (e.g., a court or a government agency).
            </li>
            <li className='list-disc font-light'>
              <b>Marketing and Advertising Partners:</b> Information may be shared with third party
              advertising partners to send you promotional communications about products or services
              you might find interesting.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>4. Legal Basis for Processing Personal Data</b></h4>
            <li lassName='list-disc font-light'>
              Our legal basis for collecting and using the personal information described above will depend on the personal 
              information concerned and the specific context in which we collect it.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>5. Security of Your Information</b></h4>
            <li lassName='list-disc font-light'>
              We use administrative, technical, and physical security measures to help protect your personal
              information. While we have taken reasonable steps to secure the personal information you
              provide to us, please be aware that despite our efforts, no security measures are perfect or
              impenetrable, and no method of data transmission can be guaranteed against any interception or
              other type of misuse.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>6. International Transfers</b></h4>
            <li lassName='list-disc font-light'>
              Your information, including personal data, may be transferred to—and maintained on—
              computers located outside of your state, province, country, or other governmental jurisdiction
              where the data protection laws may differ from those of your jurisdiction.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
           <div>
            <h4 className='underline text-start'><b>7. Your Data Protection Rights</b></h4>
            <p>You have the following data protection rights:</p>
           </div>
            <li lassName='list-disc font-light'>The right to access, update or delete the information we have on you.</li>
            <li lassName='list-disc font-light'>The right of rectification.</li>
            <li lassName='list-disc font-light'>The right to object.</li>
            <li lassName='list-disc font-light'>The right of restriction.</li>
            <li lassName='list-disc font-light'>The right to data portability.</li>
            <li lassName='list-disc font-light'>The right to withdraw consent.</li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>8. Links to Other Websites</b></h4>
            <li lassName='list-disc font-light'>
              Our Service may contain links to other websites that are not operated by us. If you click a third
              party link, you will be directed to that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>9. Changes to This Privacy Policy</b></h4>
            <li lassName='list-disc font-light'>
              We may update our Privacy Policy from time to time. We will notify you of any changes by
              posting the new Privacy Policy on this page.
            </li>
          </div>
          <div className='space-y-1 text-justify'>
            <h4 className='underline text-start'><b>10. Contact Us</b></h4>
            <li lassName='list-disc font-light'>If you have any questions about (info@codewavetechnology.com).</li>
          </div>
        </div>

        <br />
        <br />
        <span className='text-blue-600 font-bold'>By using our Service, you acknowledge that you have read and understood this Privacy Policy.</span>
      </section>
    </main>
  )
}

export default CustomerTerms
