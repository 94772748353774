import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';


const AdvertiserRoute = ({ children }) => {
  const token = window.localStorage.getItem('accessToken');
  const parsedItem = token ? JSON.parse(token).data.user : null;

  if (!parsedItem || (parsedItem.role !== 'ADMIN' && parsedItem.role !== 'ADVERTISER')) {
    return <Navigate to='/unauthorised' replace />;
  }
  return children;
};

export default AdvertiserRoute;

AdvertiserRoute.propTypes = {
  children: PropTypes.node.isRequired
};
