import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import CreateCampaign from '../AdvertCampaign/CreateCampaign.js';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import { MdOutlineClose, MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';
import Pagination from '../Pagination';

const AdvertCampaign = () => {

  const [viewcampaign, setViewcampaign] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);

  // getting campaign by id
  const getCampaignById = useCallback(async (offset, limit) => {
    try {
      const response = await axios.get(`/advert-campaigns/?advert_id=${id}&offset=${offset}&limit=${limit}`);
      setViewcampaign(response.data.items);
      setLoading(false)
      setError(null);
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [id]);

  useEffect(() => {
    getCampaignById((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getCampaignById]);

  // end a campaign
  const endCampaign = async (id) => {
    const confirmed = window.confirm('Are you sure you want to end this campaign');
    if (confirmed) {
      try {
        await axios.put(`/advert-campaigns/${id}/end-campaign`);
        toast.success('Camapign Ended');
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      } catch (error) {
        toast.error('Failed to end campaign');
        console.log(error);
      }
    }
  };

  // Modal to view add camapign by advert id
  const openModal = () => {
    const dialog = document.getElementById('my_modal_3');
    if (dialog !== null) {
      dialog.showModal();
    }
  };
  
  return (
    <div>
      <section className='my-4 bg-white p-4 rounded-lg '>
        <div className='py-2 flex justify-between items-center'>
          <h5 className='text-[#6735b7] font-bold'>Showing {viewcampaign.length} Campaigns</h5>
          <button className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-md' onClick={() => openModal(viewcampaign)}>Add Campaign</button>
          {/* create campaign modal */}
          <dialog id="my_modal_3" className="modal">
            <div className="modal-box">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              {/* create campain section */}
              <section>
                <CreateCampaign />
              </section>
            </div>
          </dialog>
        </div>

        {loading ? (
          <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
            <Loader />
          </div>
        ) : error ? (
          <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
            <div className='grid place-items-center text-red-600 p-4'>
              <h3><MdOutlineBlock /></h3>
              <span>{error}</span>
            </div>
          </div>
        ) : (
          <div className='overflow-x-auto rounded-lg'>
            {viewcampaign.length > 0
              ? (
                <table className='w-full text-left table-auto'>
                  <thead>
                    <tr className='border-b border-slate-500'>
                      <th className='py-3 px-6'>No</th>
                      <th className='py-3 px-6'>Name</th>
                      <th className='py-3 px-6'>Advert Id</th>
                      <th className='py-3 px-6'>Start Date</th>
                      <th className='py-3 px-6'>End Date</th>
                      <th className='py-3 px-6'>Impressions</th>
                      <th className='py-3 px-6'>Clicks</th>
                      <th className='py-3 px-6'>Reachs</th>
                      <th className='py-3 px-6'>Status</th>
                      <th className='py-3 px-6'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewcampaign.map((campaign) => (
                      <tr key={campaign.id}>
                        <td className='py-2 px-6'>{campaign.id}</td>
                          <td className='py-2 px-6'>{campaign.name}</td>
                          <td className='py-2 px-6'>{campaign.advert_id}</td>
                          <td className='py-2 px-6'>{new Date(campaign.start_date).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          <td className='py-2 px-6'>{new Date(campaign.end_date).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          <td className='py-2 px-6'>{campaign.actual_impressions}</td>
                          <td className='py-2 px-6'>{campaign.actual_clicks}</td>
                          <td className='py-2 px-6'>{campaign.actual_reach}</td>
                          <td className='py-2 px-6'>{campaign.is_live === true
                            ? (
                              <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Open</span>
                              )
                            : (
                              <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Closed</span>
                              )}
                          </td>
                          <td className='py-2 px-6'>
                            <button onClick={() => endCampaign(campaign.id)} className='text-red-700 px-5 py-1 rounded-md hover:text-red-400 flex items-center'><span><MdOutlineClose /></span>End Campaign</button>
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                )
              : (
                <div className='bg-[#f2ecfc] text-[#6735b7] grid place-items-center h-[70vh]'>
                  <div className='grid place-items-center p-4'>
                    <h3><MdOutlineBlock /></h3>
                    <h4>No Data</h4>
                  </div>
                </div>
                )}
          </div>
        )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(viewcampaign / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </div>
  )
}

export default AdvertCampaign