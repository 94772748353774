import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../Pagination';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';

const GetOrganisationMembers = () => {

  const [organisationMember, setOrganisationMember] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);


  // Fetch All organisation members by id
  const getorganisationMember = useCallback(async (offset, limit) => {
    try {
      const response = await axios.get(`/organizations/${id}/members?offset=${offset}&limit=${limit}`);
      setOrganisationMember(response.data.items);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [id]);

  useEffect(() => {
    getorganisationMember((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getorganisationMember]);


  return (
    <div className='w-[92vw] xl:w-[78vw] 2xl:w-full'>
      <section className='my-4 bg-white rounded-lg p-4 mb-4'>
        <div className='flex flex-wrap items-center justify-between py-3 gap-2'>
          <Link className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg' to={`/app/addorganisationmember/${id}`}>Add Member</Link>
          <h5 className='text-[#6735b7] font-bold'>Showing {organisationMember.length} organisation members</h5>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] p-4'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto'>
                {organisationMember.length > 0
                  ? (
                    <table className='w-full text-left table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Name</th>
                          <th className='py-3 px-6'>Email</th>
                          <th className='py-3 px-6'>Role</th>
                          <th className='py-3 px-6'>Status</th>
                          <th className='py-3 px-6'>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {organisationMember.map((organisation) => (
                          <tr key={organisation.id}>
                            <td className='py-2 px-6'>{organisation.id}</td>
                            <td className='py-2 px-6'>{organisation.user_name}</td>
                            <td className='py-2 px-6'>{organisation.user_email}</td>
                            <td className='py-2 px-6'>{organisation.role}</td>
                            <td className='py-2 px-6'>
                              {organisation.is_enabled === true
                                ? (
                                  <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Enabled</span>
                                  )
                                : (
                                  <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Disabled</span>
                                  )}
                            </td>
                            <td className='py-2 px-6'>{new Date(organisation.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          </tr>

                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(organisationMember / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </div>
  );
};

export default GetOrganisationMembers;
