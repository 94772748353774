import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';
import { Link, useParams } from 'react-router-dom';
import { FaRegEye } from 'react-icons/fa';

const OrganisationWallet = () => {

  const [orgWallet, setOrgWallet] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);

  // Fetch All organization wallets
  const getwallets = useCallback(async (offset,limit) => {
    try {
      const response = await axios.get(`/wallets/?organization_id=${id}&offset=${offset}&limit=${limit}`);
      setOrgWallet(response.data.items);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [id]);

  useEffect(() => {
    getwallets((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getwallets]);


  return (
    <section className='my-4 bg-white p-4 rounded-lg w-[92vw] xl:w-[78vw] 2xl:w-full'>
      <div className='py-3'>
        <h5 className='text-[#6735b7] font-bold'>Showing {orgWallet.length} wallets</h5>
      </div>
      {loading
        ? (
          <div className='flex items-center justify-center bg-[#f2ecfc] p-4'>
            <Loader />
          </div>
          )
        : error
          ? (
            <div className='bg-[#f2ecfc] grid place-items-center'>
              <div className='grid place-items-center text-red-600 p-4'>
                <h3><MdOutlineBlock /></h3>
                <span>{error}</span>
              </div>
            </div>
            )
          : (
            <div className='overflow-x-auto'>
              {orgWallet.length > 0
                ? (
                  <table className='w-full text-left table-auto'>
                    <thead>
                      <tr className='border-b border-slate-500'>
                        <th className='py-3 px-6'>No</th>
                        <th className='py-3 px-6'>Org Id</th>
                        <th className='py-3 px-6'>Balance</th>
                        <th className='py-3 px-6'>Currency Id</th>
                        <th className='py-3 px-6'>Created At</th>
                        <th className='py-3 px-6'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgWallet.map((wallet) => (
                        <tr key={wallet.id}>
                          <td className='py-2 px-6'>{wallet.id}</td>
                          <td className='py-2 px-6'>{wallet.organization_id}</td>
                          <td className='py-2 px-6'>{wallet.balance}</td>
                          <td className='py-2 px-6'>{wallet.currency_id}</td>
                          <td className='py-2 px-6'>{new Date(wallet.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          <td className='py-2 px-6'>
                            <span className='text-blue-600 hover:text-blue-900 text-xl flex items-center justify-center'><Link to={`/app/viewwallet/${wallet.id}`}><FaRegEye /></Link></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  )
                : (
                  <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center'>
                    <div className='grid place-items-center p-4'>
                      <h3><MdOutlineBlock /></h3>
                      <h4>No Wallet</h4>
                    </div>
                  </div>
                  )}
            </div>
            )}
      <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
        <div>
          <span className='mr-2'>Records per page:</span>
          <select
            className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
            value={recordsPerPage}
            onChange={(e) => {
              setRecordsPerPage(parseInt(e.target.value, 10));
              setCurrentPage(1);
            }}
          >
            <option value='5'>5</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </div>
        <Pagination
          nPages={Math.ceil(orgWallet / recordsPerPage)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  );
};

export default OrganisationWallet;
