import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'sonner';
import { MdOutlineClose, MdDeleteForever, MdEdit } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';
import AdvertCampaign from './AdvertCampaign'

const ViewAdvert = () => {

  const [viewadvert, setViewadvert] = useState([]);
  const { id } = useParams();

  // getting advert by id
  const getAdvertById = useCallback(async () => {
    try {
      const response = await axios.get(`/adverts/ads/${id}`);
      setViewadvert(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    getAdvertById();
  }, [getAdvertById]);

  // enable an advert
  const enableAdvert = async (id) => {
    const confirmed = window.confirm('Are you sure you want to enable this advert');
    if (confirmed) {
      try {
        await axios.put(`/adverts/ads/${id}/enable`);
        toast.success('Advert Enabled');
        getAdvertById();
      } catch (error) {
        toast.error('Failed to enable advert');
        console.log(error);
      }
    }
  };

  // disable an advert
  const disableAdvert = async (id) => {
    const confirmed = window.confirm('Are you sure you want to disable this advert');
    if (confirmed) {
      try {
        await axios.put(`/adverts/ads/${id}/disable`);
        toast.success('Advert Disabled');
        getAdvertById();
      } catch (error) {
        toast.error('Failed to disable advert');
        console.log(error);
      }
    }
  };

  // Approve an advert
  const approveAdvert = async (id) => {
    const confirmed = window.confirm('Are you sure you want to approve this advert');
    if (confirmed) {
      try {
        await axios.put(`/adverts/ads/${id}/approve`);
        toast.success('Advert Approved');
        getAdvertById();
      } catch (error) {
        toast.error('Failed to approve advert. Enable or unsuspend advert first');
        console.log(error);
      }
    }
  };

  // suspend an advert
  const suspendAdvert = async (id) => {
    const confirmed = window.confirm('Are you sure you want to suspend this advert');
    if (confirmed) {
      try {
        await axios.put(`/adverts/ads/${id}/suspend`);
        toast.success('Advert Suspended');
        getAdvertById();
      } catch (error) {
        toast.error('Failed to suspend advert');
        console.log(error);
      }
    }
  };

  // Unsuspend an advert
  const unSuspendAdvert = async (id) => {
    const confirmed = window.confirm('Are you sure you want to unsuspend this advert');
    if (confirmed) {
      try {
        await axios.put(`/adverts/ads/${id}/un-suspend`);
        toast.success('Advert Un-suspended');
        getAdvertById();
      } catch (error) {
        toast.error('Failed to un-suspend advert');
        console.log(error);
      }
    }
  };


  // getting current user role
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user.role;

  return (
    <div>
      <div className='mx-auto p-4'>
        {/* advert details */}
        <section className='bg-white rounded-md p-4  '>
          <div className='pb-4'>
            <h3 className='text-center text-[#8144E5] font-bold mb-4'><u>Advert Name : {viewadvert.advert_name}</u></h3>
            <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
              <div><span className='font-bold'>Product Name : </span>{viewadvert.product_name}</div>
              <div><span className='font-bold'>Product Price : </span>{viewadvert.product_price}</div>
              <div><span className='font-bold'>Product Description : </span>{viewadvert.product_description}</div>
              <div><span className='font-bold'>Objective : </span>{viewadvert.objective}</div>
              <div><span className='font-bold'>Organization Id : </span>{viewadvert.organization_id}</div>
              <div><span className='font-bold'>Category Id : </span>{viewadvert.category_id}</div>
              <div><span className='font-bold'>Sub Category Id : </span>{viewadvert.sub_category_id}</div>
              <div><span className='font-bold'>Media Duration Seconds : </span>{viewadvert.media_duration_seconds} Seconds</div>
              <div><span className='font-bold'>Min Play Duration : </span>{viewadvert.min_play_duration_seconds} Seconds</div>
              <div><span className='font-bold'>Approval : </span>
                {viewadvert.approval_status === 'APPROVED' ? (
                  <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Approved</span>
                ) : (
                  <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
                )}
              </div>
              <div><span className='font-bold'>Media Type : </span>{viewadvert.media_type}</div>
              <div><span className='font-bold'>Clickable : </span>{viewadvert.is_clickable === true ? (<span>Yes</span>) : (<span>No</span>)}</div>
              <div><span className='font-bold'>Action to : </span><a href={viewadvert.click_url} target='_blank' rel='noreferrer'><u className='text-blue-700 hover:text-blue-500'>{viewadvert.click_url}</u></a></div>
              <div><span className='font-bold'>Time Created : </span>{viewadvert.time_created ? new Date(viewadvert.time_created).toISOString().replace('T', ' ').slice(0, 19) : '...'}</div>
              <div><span className='font-bold'>Live : </span>
                {viewadvert.is_live === true ? (
                  <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>On Air</span>
                ) : (
                  <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Off Air</span>
                )}
              </div>
              <div><span className='font-bold'>Created By : </span>{viewadvert.created_by_user_id}</div>
              <div><span className='font-bold'>Approved By : </span>{viewadvert.approved_by_user_id}</div>
              <div><span className='font-bold'>Suspended By : </span>{viewadvert.suspended_by_user_id}</div>
              <div><span className='font-bold'>Cummulative Impressions : </span>{viewadvert.cumulative_impressions}</div>
              <div><span className='font-bold'>Suspension : </span>
                {viewadvert.is_suspended === true ? (
                  <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Suspended</span>
                ) : (
                  <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>UnSuspended</span>
                )}
              </div>
              <div><span className='font-bold'>Cummulative Reach : </span>{viewadvert.cumulative_reach}</div>
              <div><span className='font-bold'>Cummulative Clicks : </span>{viewadvert.cumulative_clicks}</div>
              <div><span className='font-bold'>Click Action : </span>{viewadvert.click_action_type}</div>
            </div>
            <div>
              <span className='font-bold'>Advert File : </span>
              {viewadvert.media_type === 'video' ? (
                <div>
                  <video width='320' height='240' controls muted>
                    <source src={viewadvert.asset_url} type='video/mp4' />
                  </video>
                </div>
              ) : (
                <img width={300} height={400} src={viewadvert.asset_url} alt='' />
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 justify-center mt-4'>
            <Link to={`/app/updateadvert/${viewadvert.id}`}><span className='bg-blue-600 text-white px-5 py-1 rounded-md hover:bg-blue-400 flex items-center'><MdEdit />Update Advert</span></Link>
            <Link to={`/app/updateadvertmedia/${viewadvert.id}`}><span className='bg-blue-600 text-white px-5 py-1 rounded-md hover:bg-blue-400 flex items-center'><MdEdit />Update Media File</span></Link>
            <div>
            {user === 'ADVERTISOR' && (
              <div className='flex flex-wrap gap-4'>
                {viewadvert.is_live === true ? (
                  <button onClick={() => disableAdvert(viewadvert.id)} className='bg-yellow-600 text-white px-5 py-1 rounded-md hover:bg-red-400 flex items-center'><span><MdOutlineClose /></span>Disable</button>
                ) : (
                  <button onClick={() => enableAdvert(viewadvert.id)} className='bg-green-600 text-white px-5 py-1 rounded-md hover:bg-green-400 flex items-center gap-1'><span><IoCheckmarkSharp /></span>Enable</button>
                )}
              </div>
            )}
            </div>
            <div>
              {user === 'ADMIN' && (
                <div className='flex flex-wrap justify-center gap-5'>
                  {viewadvert.is_live === true ? (
                    <button onClick={() => disableAdvert(viewadvert.id)} className='bg-yellow-600 text-white px-5 py-1 rounded-md hover:bg-red-400 flex items-center'><span><MdOutlineClose /></span>Disable</button>
                  ) : (
                    <button onClick={() => enableAdvert(viewadvert.id)} className='bg-green-600 text-white px-5 py-1 rounded-md hover:bg-green-400 flex items-center gap-1'><span><IoCheckmarkSharp /></span>Enable</button>
                  )}
                  {viewadvert.approval_status === 'PENDING' && (
                    <button onClick={() => approveAdvert(viewadvert.id)} className='bg-blue-600 text-white px-5 py-1 rounded-md hover:bg-green-400 flex items-center'><span><IoCheckmarkSharp /></span>Approve</button>
                  )}
                  {viewadvert.is_suspended === true ? (
                    <button onClick={() => unSuspendAdvert(viewadvert.id)} className='bg-purple-600 text-white px-5 py-1 rounded-md hover:bg-purple-400'>Unsuspend</button>
                  ) : (
                    <button onClick={() => suspendAdvert(viewadvert.id)} className='bg-red-600 text-white px-5 py-1 rounded-md hover:bg-red-400 flex items-center'><span><MdDeleteForever /></span>Suspend</button>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* advert campaign details */}
        <AdvertCampaign/>
      </div>
    </div>
  );
};

export default ViewAdvert;
