import React from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorised = () => {
  const navigate = useNavigate();

  return (
    <div className='container mx-auto p-4'>
      <div className='flex items-center justify-center h-screen'>
        <div className='space-y-3'>
          <h1>403 Unauthorised</h1>
          <p>...Oops you are not authorised to view this page</p>
          <button onClick={() => navigate(-1)} className='bg-green-600 text-white px-5 py-2 rounded md hover:bg-green-500'>Go Back</button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorised;
