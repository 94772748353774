import React, { useState, useEffect } from 'react';
import axios from '../api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const VerificationCode = () => {

  const [msisdn_code, setMsisdn_code] = useState('');
  const [email_code, setEmail_code] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.post('/security/verify-phone-email', 
        { msisdn_code, email_code },
      );
      toast.success('User Verification Successful');
      navigate('/login');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response?.status === 401 || 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('User Verification Failed');
      }
      console.log(error.response);
    } finally {
      setLoading(false)
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault()
    try {
      await axios.post('/security/resend-phone-verification');
      toast.success('Verification code resent');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response?.status === 401 || 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Falied to resend verification code')
      }
      console.log(error)
    }
  }

  const startCountdown = () => {
    setIsButtonDisabled(true);
    setCountdown(60);
  };

  useEffect(() => {
    // Start countdown immediately when component mounts
    startCountdown();

    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    if (countdown === 0) {
      setIsButtonDisabled(false);
      clearInterval(timer);
    }
    return () => clearInterval(timer); // Cleanup interval on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Manage countdown and button state
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    if (countdown === 0) {
      setIsButtonDisabled(false);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown]);


  return (
    <div className='flex items-center justify-center h-screen bg-[#331a5b]'>
      <div className='mx-auto p-4 bg-white rounded-lg'>
        <h4 className='font-bold text-center text-[#8144E5] underline'>Verify Details</h4>
        <span>Enter verification code sent to your phone and email to activate your account.</span>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='code'>Phone Code
              <input
                type='number'
                required
                placeholder='Code'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={msisdn_code}
                onChange={(e) => setMsisdn_code(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='code'>Email Code
              <input
                type='number'
                required
                placeholder='Code'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={email_code}
                onChange={(e) => setEmail_code(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 flex gap-4'>
            <button onClick={handleResendCode} disabled={isButtonDisabled} className='px-3 py-1 w-full bg-slate-700 text-white hover:bg-slate-600  disabled:bg-neutral-500 disabled:cursor-not-allowed'>
              {isButtonDisabled ? `Resend Code in ${countdown}s` : 'Resend Code'}
            </button>
            <button disabled={loading} className='px-3 py-1 w-full bg-blue-700 text-white hover:bg-blue-500'>{loading ? 'Verifying...' : 'Verify'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerificationCode;
