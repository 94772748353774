import React, { useState } from 'react';
import axios from '../api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import Login from './Login'

const TwoFaLogin = ({ setAccessToken }) => {

  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('accessToken')
  const tokenData = JSON.parse(token)?.data.access_token;
  const bearer = tokenData

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const token = await axios.post('/2fa-login',
        { code },
        {
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          },
        }
      );
      toast.success('Login Successful');
      navigate('/app/dashboard');
      setAccessToken(token);
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response?.status === 401 || 400) {
      toast.error(error.response.data.detail);
      } else {
      toast.error('User Verification Failed');
      }
      console.log(error.response);
    } finally {
      setLoading(false)
    }
  };

  const twofatoken = window.localStorage.getItem('accessToken');
  const use = JSON.parse(twofatoken)?.data.token_use;

  if (use !== '2fa_login') {
    return <Login/>
  }



  return (
    <div className='flex items-center justify-center h-screen bg-[#331a5b]'>
      <div className='mx-auto p-4 bg-white rounded-lg'>
        <h4 className='font-bold text-center text-[#8144E5]'>Enter Two Step Verification Code</h4>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='code'>Verification Code
              <input
                type='number'
                required
                placeholder='Code'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <button disabled={loading} className='px-3 py-1 w-full bg-[#331a5b] text-white hover:bg-[#8144E5]'>{loading ? 'Verifying...' : 'Verify'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TwoFaLogin;
