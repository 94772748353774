import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { MdOutlineBlock } from 'react-icons/md';
import { Link } from "react-router-dom";
import Loader from '../Loader';

const ListCustomers = () => {

  const [customer, setCustomer] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)
  

  // Fetch All customer
  const getCustomer = useCallback(async (offset, limit, msisdn) => {
    try {
      let query = `?offset=${offset}&limit=${limit}`

      if (msisdn) {
        query += `&msisdn=${msisdn}`
      }

      const response = await axios.get(`/ad-consumer-customers/${query}`);
      setCustomer(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(true);
      getCustomer((currentPage - 1) * recordsPerPage, recordsPerPage, searchCustomer);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [currentPage, recordsPerPage, searchCustomer, getCustomer]);
  

  // search customer with phone number
  const handleSearchChange = (e) => {
    setSearchCustomer(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4'>
        <div className='flex flex-wrap items-center justify-between py-3'>
        <Link to='/app/addcustomer' className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg'>Add Customer</Link>
          <h5 className='text-[#6735b7] font-bold'>Showing {count} customer</h5>
          <div className='mt-2 md:mt-0'>
            <form action=''>
              <label htmlFor='search'><span className='hidden'>Search</span>
                <input
                  type='search'
                  className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 placeholder-slate-400 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                  required
                  placeholder='Search Phone'
                  value={searchCustomer}
                  onChange={handleSearchChange}
                />
              </label>
            </form>
          </div>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {customer.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No.</th>
                          <th className='py-3 px-6'>Phone Number</th>
                          <th className='py-3 px-6'>Country Id</th>
                          <th className='py-3 px-6'>Daily Ads Number Limit</th>
                          <th className='py-3 px-6'>Is Enabled</th>
                          <th className='py-3 px-6'>Is Opted In</th>
                          <th className='py-3 px-6'>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customer.map((customer) => (
                          <tr key={customer.id}>
                            <td className='py-2 px-6'>{customer.id}</td>
                            <td className='py-2 px-6'>{customer.msisdn}</td>
                            <td className='py-2 px-6'>{customer.country_id}</td>
                            <td className='py-2 px-6'>{customer.daily_ads_num_limit}</td>
                            <td className='py-2 px-6'>{customer.is_enabled === true ? (
                              <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Enabled</span>
                            ) : (
                              <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Disabled</span>
                            )}</td>
                            <td className='py-2 px-6'>{customer.is_opted_in === true ? (
                              <span className='bg-[#D9E7FA] text-[#215CB1] px-4 py-1 font-bold rounded-full'>Opted In</span>
                            ) : (
                              <span className='bg-[#FDE6D8] text-[#AC5A2B] font-bold px-3 py-1 rounded-full'>Opted Out</span>
                            )}</td>
                            <td className='py-2 px-6'>{new Date(customer.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center h-[70vh]'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListCustomers;
