import React, { useState } from 'react';
import axios from '../api/api';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import PropTypes from 'prop-types';

const Signup = ({setAccessToken}) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [role, setRole] = useState('ADVERTISER');
  const [accepted_terms, setAccepted_terms] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // sign up function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    if (password.length < 8 || confirmPassword.length < 8) {
      toast('Password must be more than 8 characters');
      return;
    }
    setLoading(true);
    try {
      const token = await axios.post('/users/signup',
        { name, email, msisdn, role, accepted_terms, password }
      );
      setAccessToken(token)
      toast.success('Registration Successful');
      navigate('/verification');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response.status === 422) {
        toast.error(error.response.data.detail[0].msg)
      } else {
        toast.error('Registration Error');
      }
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className='bg-[#331a5b]'>
        <div className='flex items-center justify-center h-screen p-4'>
          <div className='p-4 bg-white w-full xl:w-1/2 rounded-md'>
            <div className='flex flex-wrap items-center justify-center xl:flex-nowrap'>
              <div className='w-full flex items-center justify-center'>
                <img width={400} height={400} src='https://img.freepik.com/premium-vector/advertising-ads-vector-illustration_2175-4777.jpg?w=1380' alt='' />
              </div>
              <form onSubmit={handleSubmit} className='w-full'>
                <div className='text-center py-2'>
                  <h3 className='font-bold text-[#8144E5]'>Street Smart Ads</h3>
                  <p>Create Your Account Here!</p>
                </div>
                <div>
                  <label htmlFor='name'><span className='hidden'>Full name</span>
                    <input
                      type='text'
                      required
                      placeholder='Full Names'
                      className='mt-4 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='Email'><span className='hidden'>Email</span>
                    <input
                      type='email'
                      required
                      placeholder='Email Address'
                      className='mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='role'><span className='hidden'>Role</span>
                    <input
                      type='test'
                      required
                      placeholder='Role'
                      className='mt-2 px-3 py-2 hidden bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] w-full rounded-md sm:text-sm focus:ring-1'
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='Phone'><span className='hidden'>Phone</span>
                    <input
                      type='number'
                      required
                      placeholder='Phone Number'
                      className='mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                      value={msisdn}
                      onChange={(e) => setMsisdn(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='Password'><span className='hidden'>Password</span>
                    <input
                      type='password'
                      required
                      placeholder='Password'
                      className='mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='Password'><span className='hidden'>Confirm Password</span>
                    <input
                      type='password'
                      required
                      placeholder='Comfirm Password'
                      className='mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </label>
                </div>
                <div className='mt-1 flex items-center space-x-1'>
                  <label htmlFor="checkbox"><span className='hidden'>Terms&condition</span></label>
                  <input type="checkbox" className='items-center' value={accepted_terms} onChange={(e) => setAccepted_terms(e.target.value)} required />
                  <Link to='/terms&condition' className='text-blue-600 hover:text-blue-800'><u>I accept the Terms & Conditions</u></Link>
                </div>
                <div className='text-center py-3'>
                  <button type='submit' disabled={loading} className='px-6 py-2 bg-[#331a5b] text-white hover:bg-[#8144E5] rounded-md w-full'>{loading ? 'Please wait...' : 'Sign Up'}</button>
                </div>
                <div>
                  <span>Already have an account? <u className='text-blue-600 hover:text-blue-800'><Link to='/login'>Sign In Here</Link></u></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Signup;

Signup.propTypes = {
  setAccessToken: PropTypes.func
};