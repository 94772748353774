import React, { useState } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';

const CreateOrgDocument = () => {

  const { id } = useParams();
  const [name, setName] = useState('');
  const [document_type, setDocument_type] = useState('');
  const [description, setDescription] = useState('');
  const [media_type, setMedia_type] = useState('');
  const [user_comments, setUser_comments] = useState('');
  const [subject_organization_id, setSubject_organization_id] = useState(id);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('/kyc-documents/organization-docs',
        { name, document_type, description, media_type, user_comments, subject_organization_id }
      );
      toast.success('Document added successfully!');
      if (response.status === 201) {
        const { id } = response.data;
        navigate(`/app/updatedocument/${id}`);
      }
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else {
        toast.error('Error adding document.');
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Create KYC Document</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='name'>Document Name
              <input
                type='text'
                required
                placeholder='Document Name'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='document'>Document type
              <select
                name='' id=''
                required
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={document_type}
                onChange={(e) => setDocument_type(e.target.value)}
              >
                <option value=''>Document Type</option>
                <option value='NATIONAL_ID'>National Id</option>
                <option value='PASSPORT'>National Passport</option>
                <option value='DRIVING_LICENSE'>Driving Licence</option>
                <option value='NHIF_CARD'>Nhif Card</option>
                <option value='NSSF_CARD'>Nssf Card</option>
                <option value='KRA_PIN_CERTIFICATE'>Kra Pin Certificate</option>
                <option value='COMPANY_REGISTRATION_CERTIFICATE'>Company Registration Certificate</option>
                <option value='BUSINESS_NAME_REGISTRATION_CERTIFICATE'>Business Name Registration Certificate</option>
                <option value='COUNTY_GOVERNMENT_BUSINESS_PERMIT'>County Government Business Permit</option>
                <option value='MEMORANDUM_OF_ASSOCIATION'>Memorandum of Association</option>
                <option value='ARTICLES_OF_ASSOCIATION'>Articles of Association</option>
                <option value='BUSINESS_MEETING_MINUTES'>Business Meeting Minutes</option>
                <option value='LETTER_OF_APPOINTMENT_OF_DIRECTORS'>Letter of Appointment of Directors</option>
                <option value='OTHER'>Others</option>
              </select>
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='desctiption'>Document Description
              <textarea
                name='' id='desctiption'
                cols='30' rows='5'
  
                placeholder='Description'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='file'>File Type
              <select
                name='' id='file'
                required
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={media_type}
                onChange={(e) => setMedia_type(e.target.value)}
              >
                <option value=''>Upload Media Type</option>
                <option value='IMAGE'>Image</option>
                <option value='PDF'>Pdf</option>
              </select>
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='comment'>User Comment
              <textarea
                name='' id='comment'
                cols='30' rows='5'
                placeholder='User Comment'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={user_comments}
                onChange={(e) => setUser_comments(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 hidden'>
            <label htmlFor='orgid'>Organisation Id
              <input
                type='number'
                required
                disabled
                placeholder='Organisation Id'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1'
                value={subject_organization_id}
                onChange={(e) => setSubject_organization_id(e.target.value)}
              />
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Please wait...' : 'Create Document'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrgDocument;
