import React, { useState } from 'react';
import axios from '../api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import PropTypes from 'prop-types';

const GetVerificationCode = ({setAccessToken}) => {

  const [msisdn, setMsisdn] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const token = await axios.post('/users/signup-verification-token', 
        { msisdn, email },
      );
      setAccessToken(token)
      toast.success('Verification code sent');
      navigate('/verification');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response?.status === 401 || 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Failed to send verification code');
      }
      console.log(error.response);
    } finally {
      setLoading(false)
    }
  };


  return (
    <div className='flex items-center justify-center h-screen bg-[#331a5b]'>
      <div className='mx-auto p-4 bg-white rounded-lg'>
        <h4 className='font-bold text-center text-[#8144E5] underline'>Enter Your Details To Get Verification Codes</h4>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='code'>Phone Number
              <input
                type='number'
                required
                placeholder='070000000'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='code'>Email Address
              <input
                type='email'
                required
                placeholder='user@gmail.com'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 flex gap-4'>
            <button disabled={loading} className='px-3 py-1 w-full bg-blue-700 text-white hover:bg-blue-500'>{loading ? 'Sending...' : 'Get Code'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GetVerificationCode;

GetVerificationCode.propTypes = {
  setAccessToken: PropTypes.func
};