import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { MdOutlineClose } from 'react-icons/md';

const ViewCampaign = () => {
  const [viewcampaign, setViewcampaign] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  // getting advert by id
  useEffect(() => {
    const getCamapignById = async () => {
      try {
        const response = await axios.get(`/advert-campaigns/${id}`);
        setViewcampaign(response.data);
      } catch (error) {
        console.error('Error fetching campaign:', error);
      }
    };
    getCamapignById();
  }, [id]);

  // end a campaign
  const endCampaign = async (id) => {
    const confirmed = window.confirm('Are you sure you want to end this campaign');
    if (confirmed) {
      try {
        await axios.put(`/advert-campaigns/${id}/end-campaign`);
        toast.success('Camapign Ended');
        navigate('/app/listcampaign');
      } catch (error) {
        toast.error('Failed to end campaign');
        console.log(error);
      }
    }
  };

  return (
    <div className='mx-auto p-4'>
      <div className='bg-white p-4'>
        <div className='pb-4'>
          <h3 className='text-center text-[#8144E5] font-bold mb-4'><u>Campaign Name : {viewcampaign.name}</u></h3>
          <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
            <div><span className='font-bold'>Start Date : </span>{viewcampaign.start_date ? new Date(viewcampaign.start_date).toISOString().replace('T', ' ').slice(0, 19) : '...'}</div>
            <div><span className='font-bold'>End Date : </span>{viewcampaign.end_date ? new Date(viewcampaign.end_date).toISOString().replace('T', ' ').slice(0, 19) : '...'}</div>
            <div><span className='font-bold'>Budget : </span>{viewcampaign.budget}</div>
            <div><span className='font-bold'>Target Impressions : </span>{viewcampaign.target_impressions}</div>
            <div><span className='font-bold'>Target Clicks : </span>{viewcampaign.target_clicks}</div>
            <div><span className='font-bold'>Target Reach : </span>{viewcampaign.target_reach}</div>
            <div><span className='font-bold'>Target Genger : </span>{viewcampaign.target_gender}</div>
            <div><span className='font-bold'>Target Bands : </span>
              {viewcampaign.target_age_bands && viewcampaign.target_age_bands.length > 0
                ? viewcampaign.target_age_bands[0].age_band
                : 'N/A'}
            </div>
            <div><span className='font-bold'>Target Country Id : </span>{viewcampaign.target_country_id}</div>
            <div><span className='font-bold'>Min Play Duration : </span>{viewcampaign.created_by_user_id} Seconds</div>
            <div><span className='font-bold'>Cost Per Click : </span>{viewcampaign.cost_per_click}</div>
            <div><span className='font-bold'>Cost Per Mile : </span>{viewcampaign.cost_per_mille}</div>
            <div><span className='font-bold'>Impressions : </span>{viewcampaign.actual_impressions}</div>
            <div><span className='font-bold'>Clicks : </span>{viewcampaign.actual_clicks}</div>
            <div><span className='font-bold'>Reachs : </span>{viewcampaign.actual_reach}</div>
            <div><span className='font-bold'>Active Status : </span>
              {viewcampaign.is_live === false
                ? (
                  <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Closed</span>
                  )
                : (
                  <span className='bg-[#D9F8EB] text-[#2A8B4F] font-bold px-3 py-1 rounded-full'>Open</span>
                  )}
            </div>
            <div><span className='font-bold'>Time Created : </span>{viewcampaign.time_created ? new Date(viewcampaign.time_created).toISOString().replace('T', ' ').slice(0, 19) : '...'}</div>
          </div>
        </div>
        {viewcampaign.is_live === true && (
          <div className='py-4 justify-center flex'>
            <button onClick={() => endCampaign(viewcampaign.id)} className='bg-red-600 text-white px-5 py-1 rounded-md hover:bg-red-400 flex items-center'><span><MdOutlineClose /></span>End Campaign</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCampaign;
