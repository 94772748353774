import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../Pagination';
import { MdEdit, MdOutlineBlock } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa';
import Loader from '../Loader';

const OrganisationAdverts = () => {

  const [orgAdvert, setOrgAdvert] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);

  // getting all organisation advert by id
  const getAdvertById = useCallback(async (offset, limit) => {
    try {
      const response = await axios.get(`/adverts/ads/?organization_id=${id}&offset=${offset}&limit=${limit}`);
      setOrgAdvert(response.data.items);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [id]);

  useEffect(() => {
    getAdvertById((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getAdvertById]);

  return (
    <div className='w-[92vw] xl:w-[78vw] 2xl:w-full'>
      <section className='my-4 bg-white p-4 rounded-lg'>
        <div className='flex flex-wrap items-center justify-between py-3 gap-2'>
          <Link className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg' to={`/app/createadvert/${id}`}>Create Advert</Link>
          <h5 className='text-[#6735b7] font-bold'>Showing {orgAdvert.length} Organisation Adverts</h5>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] p-4'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {orgAdvert.length > 0
                  ? (
                    <table className='w-full text-left table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Advert Name</th>
                          <th className='py-3 px-6'>Product Name</th>
                          <th className='py-3 px-6'>Objective</th>
                          <th className='py-3 px-6'>Url</th>
                          <th className='py-3 px-6'>Status</th>
                          <th className='py-3 px-6'>Created At</th>
                          <th className='py-3 px-6'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orgAdvert.map((orgAdvert) => (
                          <tr key={orgAdvert.id}>
                            <td className='py-2 px-6'>{orgAdvert.id}</td>
                            <td className='py-2 px-6'>{orgAdvert.advert_name}</td>
                            <td className='py-2 px-6'>{orgAdvert.product_name}</td>
                            <td className='py-2 px-6'>{orgAdvert.objective}</td>
                            <td className='py-2 px-6'>{orgAdvert.click_url}</td>
                            <td className='py-2 px-6'>{orgAdvert.approval_status === 'APPROVED'
                              ? (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Approved</span>
                                )
                              : (
                                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
                                )}
                            </td>
                            <td className='py-2 px-6'>{new Date(orgAdvert.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            <td className='p-2 flex space-x-2'>
                              <span className='text-blue-600 hover:text-blue-500 text-xl flex items-center justify-center'><Link to={`/app/viewadvert/${orgAdvert.id}`}><FaRegEye /></Link></span>
                              <span className='text-blue-600 text-xl flex items-center'><Link to={`/app/updateadvert/${orgAdvert.id}`}><MdEdit /></Link></span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(orgAdvert / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </div>
  );
};

export default OrganisationAdverts;
