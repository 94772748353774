import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const AddCategory = () => {
  
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parent_category_id, setParent_category_id] = useState('');
  const [listCategory, setListCategory] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // Fetch All categories
  const getCategories = async () => {
    try {
      const response = await axios.get('/adverts/categories/');
      setListCategory(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  // create category
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.post('/adverts/categories/',
        { name, description, parent_category_id }
      );
      toast.success('Category added successfully!');
      navigate('/app/listcategories');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else {
        toast.error('Error adding category!');
      }
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Add New Category.</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='name'>Category Name
              <input
                type='text'
                required
                placeholder='Category Name'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Description'>Product Description
              <textarea
                name='' id=''
                cols='30' rows='2'
                placeholder='Description'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='category'>Parent Category
              <select
                name='' id=''
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={parent_category_id}
                onChange={(e) => setParent_category_id(e.target.value)}
              >
                <option value=''>Choose Parent category</option>
                {listCategory.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Please wait' : 'Add Category'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
