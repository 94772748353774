import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { Link } from 'react-router-dom';
import { MdEdit, MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';

const ListCategories = () => {

  const [listCategory, setListCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)

  // Fetch All categories
  const getCategories = useCallback(async (offset,limit) => {
    try {
      const response = await axios.get(`/adverts/categories/?offset=${offset}&limit=${limit}`);
      setListCategory(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  },[]);

  useEffect(() => {
    getCategories((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getCategories]);


  // getting current user role
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user.role;

  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4'>
        <div className='flex flex-wrap items-center justify-between py-3'>
        <h5 className='text-[#6735b7] font-bold'>Showing {count} Advert Categories</h5>
          {user === 'ADMIN' && (
            <Link to='/app/addcategory' className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg'>Add Category</Link>
          )}
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {listCategory.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Name</th>
                          <th className='py-3 px-6'>Parent Category Id</th>
                          <th className='py-3 px-6'>Description</th>
                          <th className='py-3 px-6'>Created At</th>
                          {user === 'ADMIN' && 
                            <th className='py-3 px-6'>Action</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {listCategory.map((category) => (
                          <tr key={category.id}>                          
                            <td className='py-2 px-6'>{category.id}</td>
                            <td className='py-2 px-6'>{category.name}</td>
                            <td className='py-2 px-6'>{category.parent_category_id}</td>
                            <td className='py-2 px-6'>{category.description}</td>
                            <td className='py-2 px-6'>{new Date(category.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            {user === 'ADMIN' && 
                              <td className='py-2 px-6'>
                                <div className='flex'>
                                  <span className='text-blue-600 text-xl'><Link to={`/app/updatecategory/${category.id}`}><MdEdit /></Link></span>
                                </div>
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center h-[70vh]'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListCategories;
