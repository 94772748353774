import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { Link } from 'react-router-dom';
import { MdEdit, MdOutlineBlock } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa';
import Loader from '../Loader';

const PendingAdverts = () => {

  const [listAdvert, setListAdvert] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)


  // Fetch All pending adverts
  const getAdvert = useCallback(async (offset,limit) => {
    try {
      const response = await axios.get(`/adverts/ads/?offset=${offset}&limit=${limit}&approval_status=PENDING`);
      setListAdvert(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, []);

  useEffect(() => {
    getAdvert((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getAdvert]);

  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4 xl:w-[80vw] 2xl:w-full'>
        <div className='flex flex-wrap items-center justify-between py-3 gap-2'>
          <h5 className='text-[#6735b7] font-bold'>Showing pending {count} Ads</h5>
          <Link to='/app/advert' className='bg-[#8144E5] hover:bg-[#6735b7] text-white px-5 py-1.5 rounded-lg'>Create Advert</Link>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {listAdvert.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Advert Name</th>
                          <th className='py-3 px-6'>Product Name</th>
                          <th className='py-3 px-6'>Impressions</th>
                          <th className='py-3 px-6'>Reach</th>
                          <th className='py-3 px-6'>Clicks</th>
                          <th className='py-3 px-6'>Approval Status</th>
                          <th className='py-3 px-6'>Live</th>
                          <th className='py-3 px-6'>Suspension</th>
                          <th className='py-3 px-6'>Created At</th>
                          <th className='py-3 px-6'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listAdvert.map((ads) => (
                          <tr key={ads.id}>
                            <td className='py-2 px-6'>{ads.id}</td>
                            <td className='py-2 px-6'><Link to={`/app/viewadvert/${ads.id}`}>{ads.advert_name}</Link></td>
                            <td className='py-2 px-6'>{ads.product_name}</td>
                            <td className='py-2 px-6'>{ads.cumulative_impressions}</td>
                            <td className='py-2 px-6'>{ads.cumulative_reach}</td>
                            <td className='py-2 px-6'>{ads.cumulative_clicks}</td>
                            <td className='py-2 px-6'>
                              {ads.approval_status === 'APPROVED' ? (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Approved</span>
                              ) : (
                                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-4 py-1 rounded-full'>Pending</span>
                              )}
                            </td>
                            <td className='py-2 px-6'>
                              {ads.is_live === true ? (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Live</span>
                              ) : (
                                <span className='bg-orange-100 text-orange-700 font-bold px-4 py-1 rounded-full'>Off Air</span>
                              )}
                            </td>
                            <td className='py-2 px-6'>
                              {ads.is_suspended === true ? (
                                <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Suspended</span>
                              )  : (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] font-bold px-4 py-1 rounded-full'>UnSuspended</span>
                              )}
                            </td>
                            <td className='py-2 px-6'>{new Date(ads.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            <td className='p-2 flex space-x-2'>
                              <span className='text-blue-600 hover:text-blue-500 text-xl flex items-center justify-center'><Link to={`/app/viewadvert/${ads.id}`}><FaRegEye /></Link></span>
                              <span className='text-blue-600 text-xl flex items-center'><Link to={`/app/updateadvert/${ads.id}`}><MdEdit /></Link></span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] grid place-items-center h-[70vh]'>
                      <div className='grid place-items-center p-4'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingAdverts;
