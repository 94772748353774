import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';

const MyProfile = () => {

  const [id_document_type, setId_document_type] = useState('');
  const [id_number, setId_number] = useState('');
  const [date_of_birth, setDate_of_birth] = useState('');
  const [nationality, setNationality] = useState('');
  const [residential_address, setResidential_address] = useState('');
  const [verification_status, setVerification_status] = useState('');

  // getting current user
  const currentUser = window.localStorage.getItem('accessToken');
  const user_id = JSON.parse(currentUser).data.user.id;

  // getting logged in advertiser
  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get('/users/me');
        const kycProfile = response.data.kyc_profile || {};
        setId_document_type(kycProfile.id_document_type || '');
        setId_number(kycProfile.id_number || '');
        setDate_of_birth(kycProfile.date_of_birth || '');
        setNationality(kycProfile.nationality || '');
        setResidential_address(kycProfile.residential_address || '');
        setVerification_status(kycProfile.verification_status || '');
      } catch (error) {
        console.error(error);
      }
    };
    getUserById();
  }, [user_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/users/${user_id}/profile`,
        { id_document_type, id_number, date_of_birth, nationality, residential_address }
      );
      toast.success('Profile updated successfully!');
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error('Failed to update profile, please try again later!');
    }
  };

  return (
    <div>
      <div className='mx-auto p-4 flex justify-center'>
        <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
          <div className='space-y-3'>
            <h3 className='text-xl text-center font-bold text-[#8144E5] underline'>Update Profile</h3>
            <span className='font-bold'>
              Status: &nbsp;
              {verification_status === 'VERIFIED' 
                ? (<span className='bg-[#D9F8EB] text-[#2A8B4F] font-bold px-3 py-1 rounded-full'>Verified</span>) 
                : (<span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>)
              }
            </span>
            {verification_status === 'PENDING' && (
              <p className='bg-[#D9E7FA] text-[#215CB1] font-bold p-2 text-center rounded-lg'>Wait until profile is approved to continue using this site.</p>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-2'>
              <label htmlFor='document'>Document Type
                <select
                  name='document' id='document'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  required
                  disabled={verification_status === 'VERIFIED'}
                  value={id_document_type}
                  onChange={(e) => setId_document_type(e.target.value)}
                >
                  <option value=''>Document Type</option>
                  <option value='NATIONAL_ID'>National Id</option>
                  <option value='PASSPORT'>Passport</option>
                  <option value='DRIVERS_LICENSE'>Driving Licence</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='idnumber'>ID Number
                <input
                  type='number'
                  placeholder='ID Number'
                  disabled={verification_status === 'VERIFIED'}
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={id_number}
                  onChange={(e) => setId_number(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='dob'>Date of Birth
                <input
                  type='date'
                  required
                  disabled={verification_status === 'VERIFIED'}
                  placeholder='Date of Birth'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={date_of_birth}
                  onChange={(e) => setDate_of_birth(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='nationality'>Nationality
                <input
                  type='text'
                  required
                  disabled={verification_status === 'VERIFIED'}
                  placeholder='Nationality'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='address'>Physical Address
                <input
                  type='text'
                  required
                  disabled={verification_status === 'VERIFIED'}
                  placeholder='Residential Address'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                  value={residential_address}
                  onChange={(e) => setResidential_address(e.target.value)}
                />
              </label>
            </div>
            <div className='py-3'>
              <button disabled={verification_status === 'VERIFIED'} type='submit' className='bg-[#8144E5] font-bold text-white px-5 py-1 w-full hover:bg-[#6735b7] disabled:bg-neutral-200 disabled:text-slate-600'>{verification_status === 'VERIFIED' ? ('Profile Verified') : 'Update Profile'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
