import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useStateContext } from '../Context/ContextProvider';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdLogout } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';

const TopBar = () => {
  const { activeMenu, setActiveMenu, setScreenSize, screenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  // getting current user
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user;

  // logout
  const signOut = () => {
    window.localStorage.removeItem('accessToken');
    window.location.reload();
  };

  return (
    <div className='flex justify-between p-2 xl:p-0.5 2xl:p-2 bg-white shadow-md z-0'>
      <div className='text-xl p-2 hover:cursor-pointer'>
        <button onClick={handleActiveMenu}><AiOutlineMenu /></button>
      </div>
      <div className='flex items-center space-x-2 px-3 divide-x-2 divide-black'>
        <h1 className='text-[#331a5b]'><FaUser /></h1>
        <div className='hover:bg-[#f2ecfc]'>
          <a className='dropdown-toggle px-4 py-1  text-black font-medium transition duration-150 ease-in-out flex items -center whitespace-nowrap ' href='/#' type='button' id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false'>
            <div className='grid'>
              <span className='font-bold text-[#331a5b]'>{user.name}</span>
              <span className='text-[#331a5b]'>{user.role}</span>
            </div>
            <span className='w-4 h-4 ml-auto mb-6 text-xl text-black'><MdOutlineKeyboardArrowDown /></span>
          </a>
          <ul className='dropdown-menu w-48 absolute  bg-white text-base z-50 float-left  py-2 list-nonetext-left  rounded-lg  shadow-lg  mt-2 hidden m-0 bg-clip-padding border-none' aria-labelledby='dropdownMenuButton2'>
            <div className='flex items-center justify-center'>
              <h1 className='text-black'><FaUser /></h1>
            </div>
            <div className='grid text-center my-2'>
              <span className='font-bold'>{user.name}</span>
              <p>{user.role}</p>
            </div>
            <hr />
            {user.role === 'ADVERTISER' && (
              <li>
                <Link className='dropdown-item text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-[#fb0000]  hover:bg-gray-100 flex items-center' to='/app/my-profile'><FiUser />&nbsp;Profile</Link>
              </li>
            )}
            <li>
              <a onClick={signOut} className='dropdown-item text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-[#fb0000]  hover:bg-gray-100 flex items-center' href='/#'><MdLogout />&nbsp;Sign Out</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
