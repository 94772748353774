import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'sonner';
import Layout from './Layout';
import Login from './pages/Login';
import TwoFaLogin from './pages/TwoFaLogin'
import Signup from './pages/Signup';
import PlatfotmTerms from './pages/PlatfotmTerms'
import CustomerTerms from './pages/CustomerTerms'
import VerificationCode from './pages/VerificationCode';
import GetVerificationCode from './pages/GetVerificationCode';
import ForgotPassword from './pages/ForgotPassword'
import PasswordReset from './pages/PasswordReset'
import Missing from './pages/Missing';
import Unauthorised from './pages/Unauthorised';
import AuthToken from './components/Context/AuthToken';

const App = () => {
  const { setAccessToken } = AuthToken();

  return (
    <main>
      <Toaster position='top-center' richColors limit={3} />
      <Routes>
        <Route path='/' element={<Login setAccessToken={setAccessToken} />} />
        <Route path='/login' element={<Login setAccessToken={setAccessToken} />} />
        <Route path='/2fa-login' element={<TwoFaLogin setAccessToken={setAccessToken} />} />
        <Route path='/signup' element={<Signup setAccessToken={setAccessToken} />} />
        <Route path='/terms&condition' element={<PlatfotmTerms />} />
        <Route path='/terms&conditions' element={<CustomerTerms />} />
        <Route path='/verification' element={<VerificationCode />} />
        <Route path='/user-verification' element={<GetVerificationCode setAccessToken={setAccessToken}/>} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route path='/app/*' element={<Layout />} />
        <Route path='/unauthorised' element={<Unauthorised />} />
        <Route path='*' element={<Missing />} />
      </Routes>
    </main>
  );
};

export default App;
