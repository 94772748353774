import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const AddCustomer = () => {

  const [msisdn, setMsisdn] = useState('');
  const [country_id, setCountry_id] = useState('');
  const [listCountry, setListCountries] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // Fetch All countries
  const getCountries = async () => {
    try {
      const response = await axios.get('/countries/');
      setListCountries(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.post('/ad-consumer-customers/',
        { msisdn, country_id }
      );
      toast.success('Customer added successfully!');
      navigate('/app/listcustomer');
    } catch (error) {
      if (!error?.response) {
        toast.error('Network error! Check your connection.');
      } else if (error.response?.status === 422) {
        toast.error('Phone Number already exist');
      } else {
        toast.error('Error adding customer!');
      }
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Add Consumer Customer</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='msisdn'>Customer Number
              <input
                type='number'
                required
                placeholder='0700000000'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='country_id'>Country
              <select
                name='country' id='country'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={country_id}
              onChange={(e) => setCountry_id(e.target.value)}
              >
                <option value=''>Select Country</option>
                {listCountry.map(counrty => (
                  <option key={counrty.id} value={counrty.id}>{counrty.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' disabled={loading} className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>{loading ? 'Please wait...' : 'Add Customer'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomer;
