import React, { useState } from 'react';
import axios from '../api/api';
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner';

const PasswordReset = () => {

  const [email, setEmail] = useState('')
  const [msisdn, setMsisdn ] = useState('')
  const [new_password, setNew_password ] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [code, setCode ] = useState('')
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    if (new_password !== confirmPassword) {
      toast('Passwords do not match')
      return
    }
    if (new_password.length < 8 || confirmPassword.length < 8) {
      toast('Password must be more than 8 characters');
      return;
    }
    try {
      await axios.post('/security/reset-password-with-sms-code', 
        { email, msisdn, new_password, code }
      )
      toast('Password reset successful')
      navigate('/')
    } catch (error) {
      console.log(error)
      toast.error('Failed to reset password')
    }finally {
      setLoading(false);
    }
  }
  return (
    <div className='bg-[#331a5b]'>
      <div className='flex items-center justify-center h-screen'>
        <form onSubmit={handleSubmit} className='bg-white p-6 rounded-lg'> 
          <h4 className='font-bold text-center text-[#8144E5]'>Reset Your Password</h4>
          <div className='mt-2'>
            <label htmlFor='Email'><span>Email Address</span>
              <input
                type='email'
                required
                placeholder='user@gmail.com'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Phone'><span>Phone Number</span>
              <input
                type='number'
                required
                placeholder='07000000000'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Phone'><span>Password</span>
              <input
                type='password'
                required
                placeholder='Password'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={new_password}
                onChange={(e) => setNew_password(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='Phone'><span>Confirm Password</span>
              <input
                type='password'
                required
                placeholder='Confirm Password'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='code'><span>Code</span>
              <input
                type='number'
                required
                placeholder='Code'
                className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#331a5b] focus:ring-[#331a5b] block w-full rounded-md sm:text-sm focus:ring-1'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <button disabled={loading} className='px-3 py-1 w-full bg-[#331a5b] text-white hover:bg-[#8144E5]'>{loading ? 'Please wait...' : 'Reset Password'}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PasswordReset
