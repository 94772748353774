import React, { useState } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

const RejectDocument = () => {

  const [staff_comments, setStaff_comments] = useState('');
  const { id } = useParams();

  // reject a document by id
  const handleReject = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/kyc-documents/${id}/reject`,
        { staff_comments }
      );
      toast.success('Document Rejected');
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error('Failed to reject document');
    }
  };

  return (
    <div>
      <form onSubmit={handleReject}>
        <div>
          <label htmlFor='comment'>Staff Reject Comment
            <input
              type='text'
              id='comment'
              required
              placeholder='Comment'
              className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
              value={staff_comments}
              onChange={(e) => setStaff_comments(e.target.value)}
            />
          </label>
        </div>
        <div className='mt-2'>
          <button className='bg-orange-600 text-white px-4 py-1 rounded-md hover:bg-red-500'>Reject Document</button>
        </div>
      </form>
    </div>
  );
};

export default RejectDocument;
