import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import VerifyDocument from './VerifyDocument';
import RejectDocument from './RejectDocument';

const ViewOrgDocument = () => {

  const [viewDocument, setViewDocument] = useState('');
  const { id } = useParams();

  // getting organisation document by id
  const getOrgDocumentById = useCallback(async () => {
    try {
      const response = await axios.get(`/kyc-documents/organization-doc/${id}`);
      setViewDocument(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    getOrgDocumentById();
  }, [getOrgDocumentById]);

  // mark document as deleted
  const deleteDocument = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this document');
    if (confirmed) {
      try {
        await axios.delete(`/kyc-documents/${id}/delete`);
        toast.success('Document Deleted');
        getOrgDocumentById();
      } catch (error) {
        toast.error('Failed to delete Document');
        console.log(error);
      }
    }
  };

  // getting current user role
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user.role;

  return (
    <div className='mx-auto p-4'>
      <section className='mb-4 bg-white rounded-lg p-4'>
        <h3 className='text-center text-[#8144E5] font-bold mb-4'><u>Document Name : {viewDocument.name}</u></h3>
        <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
          <div>
            <span className='font-bold'>Document Name : </span>
            {viewDocument.name}
          </div>
          <div>
            <span className='font-bold'>Document Type : </span>
            {viewDocument.document_type}
          </div>
          <div>
            <span className='font-bold'>Decription : </span>
            {viewDocument.description}
          </div>
          <div>
            <span className='font-bold'>Media Type : </span>
            {viewDocument.media_type}
          </div>
          <div>
            <span className='font-bold'>Verification  : </span>
            {viewDocument.verification_status === 'VERIFIED' ? (
              <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Verified</span>
            ) : viewDocument.verification_status === 'PENDING' ?  (
              <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
            ) : (
              <span className='bg-[#FDE6D8] text-[#AC5A2B] font-bold px-3 py-1 rounded-full'>Rejected</span>
            )}
          </div>
          <div>
            <span className='font-bold'>User Comments : </span>
            {viewDocument.user_comments}
          </div>
          <div>
            <span className='font-bold'>Staff Comments : </span>
            {viewDocument.staff_comments}
          </div>
          <div>
            <span className='font-bold'>Subject User Id : </span>
            {viewDocument.subject_user_id}
          </div>
          <div>
            <span className='font-bold'>Subject Organisation Id : </span>
            {viewDocument.subject_organization_id}
          </div>
          <div>
            <span className='font-bold'>Deleted : </span>
            {viewDocument.is_deleted === true ? (
              <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Deleted</span>
            ) : (
              <span className='bg-[#D9E7FA] text-[#215CB1] font-bold rounded-full px-3 py-1'>Exists</span>
            )}
          </div>
          <div>
            <span className='font-bold'>Deleted By : </span>
            {viewDocument.deleted_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Created By : </span>
            {viewDocument.created_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Update By : </span>
            {viewDocument.updated_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Verified By : </span>
            {viewDocument.verified_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Rejected By : </span>
            {viewDocument.rejected_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Created At : </span>
            {viewDocument.time_created ? new Date(viewDocument.time_created).toISOString().replace('T', ' ').slice(0, 19) : '...'}
          </div>
          <div>
            <span className='font-bold'>Updated At : </span>
            {viewDocument.time_updated ? new Date(viewDocument.time_updated).toISOString().replace('T', ' ').slice(0, 19) : '...'}
          </div>
        </div>
        <div>
          <span className='font-bold'>Media File : </span>
          {viewDocument.media_type === 'IMAGE' ? (
            <div>
              <img width={300} height={400} src={viewDocument.asset_url} alt='' />
            </div>
            ) : (
            <div>
              <iframe src={viewDocument.asset_url} width='100%' height='600px' title='pdfs' frameBorder='0'>
                <span>Your browser does not support iframes.</span>
              </iframe>
            </div>
            )}
        </div>
        {user === 'ADMIN' &&
          <div>
            <div className='flex flex-wrap items-center justify-center gap-4 mt-4'>
              <VerifyDocument />
              <RejectDocument />
            </div>
            <div className='flex flex-wrap items-center justify-center gap-4 mt-4'>
              <button onClick={() => deleteDocument(viewDocument.id)} className='bg-red-600 text-white px-5 py-1 rounded-md hover:bg-red-500'>Delete Document</button>
            </div>
          </div>
        }
      </section>
    </div>
  );
};

export default ViewOrgDocument;
