import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import DebitWallet from './DebitWallet';
import Loader from '../Loader';
import { MdOutlineBlock } from 'react-icons/md';
import Pagination from '../Pagination';

const ViewWallet = () => {

  const [viewwallet, setViewwallet] = useState('');
  const { id } = useParams();
  const [transaction_amount, setTransaction_amount] = useState('');
  const [description, setDescription] = useState('');
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);


  // getting wallet by id
  useEffect(() => {
    const getWalletById = async () => {
      try {
        const response = await axios.get(`/wallets/${id}`);
        setViewwallet(response.data);
        setLoading(false)
        setError(null);
      } catch (error) {
        console.error(error);
        setLoading(false);
        if (!error.response) {
          setError('Network error! Check your connection.');
        } else if (error.response.status >= 500) {
          setError('Server error! Please try again later.');
        } else {
          setError('An unexpected error occurred.');
        }
      }
    };
    getWalletById();
  }, [id]);

  // credit a wallet
  const handleCredit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/wallets/${id}/credit`,
        { transaction_amount, description }
      );
      toast.success('Wallet Credited');
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.detail);
    }
  };

  // getting wallet transactions by id
  const getWalletTransactionById = useCallback(async (offset, limit) => {
    try {
      const response = await axios.get(`/wallets/${id}/transactions?offset=${offset}&limit=${limit}`);
      setWalletTransactions(response.data.items);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching wallet:', error);
      setLoading(false)
      setError(error)
    }
  },[id]);

  useEffect(() => {
    getWalletTransactionById((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getWalletTransactionById]);


  // getting current user role
  const currentUser = window.localStorage.getItem('accessToken');
  const user = JSON.parse(currentUser).data.user.role;


  return (
    <div className='mx-auto p-4'>
      <div className='p-4 bg-white rounded-lg'>
        <h3 className='text-center mb-4 text-[#6735b7]'><u>Wallet Details</u></h3>
        <section>
          <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
            <div>
              <span className='font-bold'>Organisation Name : </span>
              {viewwallet.organization_name}
            </div>
            <div>
              <span className='font-bold'>Currency : </span>
              {viewwallet.currency_code}
            </div>
            <div>
              <span className='font-bold'>Balance : </span>
              {viewwallet.balance}
            </div>
            <div>
              <span className='font-bold'>Status : </span>
              {viewwallet.is_active === true ? (
                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-4 py-1 rounded-full'>Active</span>
              ) : (
                <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Dormant</span>
              )}
            </div>
          </div>
        </section>

        {user === 'ADMIN' && (
          <section className='flex flex-wrap gap-4 mt-4'>
            <div>
              <h4>Credit Wallet</h4>
              <form onSubmit={handleCredit}>
                <div>
                  <label htmlFor='amount'>Transaction Amount
                    <input
                      type='number'
                      required
                      placeholder='Amount'
                      className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                      value={transaction_amount}
                      onChange={(e) => setTransaction_amount(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor='desctiption'>Description
                    <input
                      type='text'
                      required
                      placeholder='Decription'
                      className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </label>
                </div>
                <div className='py-3'>
                  <button className='bg-green-600 text-white px-4 py-1 rounded-md'>Credit Wallet</button>
                </div>
              </form>
            </div>
            <div>
              <h4>Debit Wallet</h4>
              <DebitWallet />
            </div>
          </section>
        )}
      </div>

      {/* wallet transaction */}
      <section className='my-4 bg-white p-4 rounded-lg'>
        <div className='my-4'>
          <h5 className='text-[#6735b7] font-bold'>Showing {walletTransactions.length} Transactions</h5>
        </div>
        {loading ? (
          <div className='flex items-center justify-center bg-[#f2ecfc] h-[70vh]'>
            <Loader />
          </div>
        ) : error ? (
          <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
            <div className='grid place-items-center text-red-600 p-4'>
              <h3><MdOutlineBlock /></h3>
              <span>{error}</span>
            </div>
          </div>
        ) : (
          <div className='overflow-x-auto'>
            {walletTransactions.length > 0
              ? (
                <table className='w-full text-left table-auto'>
                  <thead>
                    <tr className='border-b border-slate-500'>
                    <th className='py-3 px-6'>No</th>           
                    <th className='py-3 px-6'>Description</th>
                    <th className='py-3 px-6'>Transaction Type</th>
                    <th className='py-3 px-6'>Transaction Amount</th>
                    <th className='py-3 px-6'>Balance Before</th>
                    <th className='py-3 px-6'>Balance After</th>
                    <th className='py-3 px-6'>Currency</th>
                    <th className='py-3 px-6'>Created By</th>
                    <th className='py-3 px-6'>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {walletTransactions.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className='py-2 px-6'>{transaction.id}</td>
                        <td className='py-2 px-6'>{transaction.description}</td>
                        <td className='py-2 px-6'>{transaction.transaction_type}</td>
                        <td className='py-2 px-6'>{transaction.transaction_amount}</td>
                        <td className='py-2 px-6'>{transaction.balance_before}</td>
                        <td className='py-2 px-6'>{transaction.balance_after}</td>
                        <td className='py-2 px-6'>{transaction.currency_code}</td>
                        <td className='py-2 px-6'>{transaction.created_by_user_id}</td>
                        <td className='py-2 px-6'>{new Date(transaction.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                )
              : (
                <div className='bg-[#f2ecfc] grid place-items-center h-[70vh]'>
                  <div className='grid place-items-center p-4'>
                    <h3><MdOutlineBlock /></h3>
                    <h4>No Data</h4>
                  </div>
                </div>
                )}
          </div>
        )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(walletTransactions / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </div>
  );
};

export default ViewWallet;
