import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';

const ListAbuseReport = () => {

  const [listAbusereport, setListAbusereport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [count, setCount] = useState(0)

  // Fetch All abusereport
  const getAbusereport = useCallback(async (offset, limit) => {
    try {
      const response = await axios.get(`/advert-interactions/abuse-reports?offset=${offset}&limit=${limit}`);
      setListAbusereport(response.data.items);
      setCount(response.data.count)
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, []);

  useEffect(() => {
    getAbusereport((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getAbusereport]);


  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4'>
        <div className='py-3'>
          <h6 className='text-[#6735b7] font-bold'>Showing {count} abuse reports</h6>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center h-[70vh] bg-[#f2ecfc]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] h-[70vh] grid place-items-center'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {listAbusereport.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Feedback type</th>
                          <th className='py-3 px-6'>Comment</th>
                          <th className='py-3 px-6'>Advert Id</th>
                          <th className='py-3 px-6'>Advert View Id</th>
                          <th className='py-3 px-6'>Customer Id</th>
                          <th className='py-3 px-6'>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ListAbuseReport.map((abusereport) => (
                          <tr key={abusereport.id}>
                            <td className='py-2 px-6'>{abusereport.id}</td>
                            <td className='py-2 px-6'>{abusereport.report_type}</td>
                            <td className='py-2 px-6'>{abusereport.comment}</td>
                            <td className='py-2 px-6'>{abusereport.advert_id}</td>
                            <td className='py-2 px-6'>{abusereport.advert_view_id}</td>
                            <td className='py-2 px-6'>{abusereport.customer_id}</td>
                            <td className='py-2 px-6'>{new Date(abusereport.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] text-[#8144E5] h-[70vh] grid place-items-center'>
                      <div className='grid place-items-center'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListAbuseReport;
