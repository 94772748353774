import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateOrganisation = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [registration_number, setRegistration_number] = useState('');
  const [registered_address, setRegistered_address] = useState('');
  const [contact_msisdn, setContact_msisdn] = useState('');
  const [contact_email, setContact_email] = useState('');
  const [purpose_of_account, setPurpose_of_account] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  // getting cateory by id
  useEffect(() => {
    const getOrganizationById = async () => {
      try {
        const response = await axios.get(`/organizations/${id}`);
        setName(response.data.name);
        setDescription(response.data.description);
        setRegistration_number(response.data.registration_number);
        setRegistered_address(response.data.registered_address);
        setContact_msisdn(response.data.contact_msisdn);
        setContact_email(response.data.contact_email);
        setPurpose_of_account(response.data.purpose_of_account);
      } catch (error) {
        console.error('Error fetching organization:', error);
      }
    };
    getOrganizationById();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/organizations/${id}`,
        { name, description, registration_number, registered_address, contact_msisdn, contact_email, purpose_of_account }
      );
      toast.success('Organisation updated successfully!');
      navigate('/app/myorganisations');
    } catch (error) {
      console.log(error);
      toast.error('Error updating Organisation.');
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Update Organisation</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='name'><span>Name</span>
              <input
                type='text'
                id='name'
                required
                autoComplete='off'
                placeholder='Category Name'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='description'><span>Description:</span>
              <input
                type='text'
                id='description'
                required
                placeholder='Description'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='id'><span>Registration NUmber:</span>
              <input
                type='number'
                id='id'
                required
                placeholder='Registration Number'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={registration_number}
                onChange={(e) => setRegistration_number(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='address'><span>Address:</span>
              <input
                type='text'
                id='address'
                autoComplete='off'
                required
                placeholder='Registered Address'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={registered_address}
                onChange={(e) => setRegistered_address(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='contact'><span>Phone Number:</span>
              <input
                type='number'
                id='contact'
                required
                placeholder='Phone Number'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={contact_msisdn}
                onChange={(e) => setContact_msisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='email'><span>Email Address:</span>
              <input
                type='email'
                id='email'
                autoComplete='off'
                required
                placeholder='Email Address'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={contact_email}
                onChange={(e) => setContact_email(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='purpose'><span>Purpose of Registration:</span>
              <textarea
                name='' id='purpose'
                cols='30' rows='5'
                required
                placeholder='Purpose of Account'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={purpose_of_account}
                onChange={(e) => setPurpose_of_account(e.target.value)}
              />
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>Update Organisation</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOrganisation;
